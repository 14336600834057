import { Fragment, ReactElement, useState } from 'react';

//components
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';

//styles and icon
import { StyledEngineProvider } from '@mui/material/styles';
import style from './styles/callout-styles.component.module.css';
import ArrowDown from '../assets/svg/arrow-down-s-line.svg';

interface CalloutProps {
	title: string;
	children: ReactElement;
	toggle?: boolean;
}

export default function Callout({ title, children, toggle }: CalloutProps) {
	const [show, setShow] = useState<boolean>(true);
	const handleChange = (expanded: boolean) => (toggle ? setShow(expanded) : null);
	return (
		<Fragment>
			<StyledEngineProvider injectFirst>
				<Accordion
					className={style.container}
					onChange={(_e, expanded) => handleChange(expanded)}
					expanded={show}
					disableGutters
					square
				>
					<AccordionSummary
						expandIcon={toggle ? <img src={ArrowDown} alt="arrow" /> : null}
					>
						<p className={style.title}>{title}</p>
					</AccordionSummary>
					<AccordionDetails className={style.body}>{children}</AccordionDetails>
				</Accordion>
			</StyledEngineProvider>
		</Fragment>
	);
}
