import React, { Fragment, useState } from 'react';
import {
	Fade,
	FormControlLabel,
	IconButton,
	InputAdornment,
	TextField,
	Tooltip,
	Typography,
} from '@mui/material';
import { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { Error, Visibility, VisibilityOff } from '@mui/icons-material';

//Assets
import styles from './styles/text-input-styles.component.module.css';
import { styled } from '@mui/material/styles';
import CustomAlert from './alert.component';

export interface ITextField {
	id: string;
	name: string;
	label?: string;
	labelComponent?: React.ReactElement;
	value: string | number | '';
	error?: boolean;
	helperText?: string | JSX.Element;
	type?: string;
	size?: 'small' | 'medium' | undefined;
	isRequired?: boolean;
	labelStyles?: React.CSSProperties;
	sx?: React.CSSProperties;
	onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
	placeholder?: string;
	onBlur?: (e: any) => void;
	onFocus?: (e: any) => void;
	onKeyDown?: React.KeyboardEventHandler;
	noCopy?: boolean;
	noPaste?: boolean;
	disabled?: boolean;
	inputProps?: object;
	InputProps?: object; //Referente a las propiedades de los Input's
	select?: boolean;
	startAdornment?: React.ReactElement;
	tooltipText?: string;
	contentStyles?: object;
	maxLength?: number | undefined;
}

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
	<Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
	[`& .${tooltipClasses.tooltip}`]: {
		backgroundColor: '#FFFFFF',
		maxWidth: 450,
		border: '1px solid #FFFFFF',

		boxShadow: theme.shadows[5],
	},
}));

const TextInput = (props: ITextField) => {
	const {
		label,
		value,
		onChange,
		id,
		name,
		helperText,
		type,
		isRequired,
		size,
		sx,
		labelStyles,
		placeholder,
		onBlur,
		onFocus,
		onKeyDown,
		noPaste,
		noCopy,
		disabled,
		inputProps,
		InputProps,
		select,
		labelComponent,
		startAdornment,
		tooltipText,
		contentStyles = {},
		maxLength,
	} = props;
	const [showPwd, setShowPwd] = useState(false);
	const handleClickShowPwd = () => setShowPwd(!showPwd);
	const handleMouseDownPwd = () => setShowPwd(!showPwd);

	return (
		<div style={{ width: '100%', ...contentStyles }}>
			<FormControlLabel
				label={
					<span
						style={{ ...labelStyles, fontFamily: 'Geomanist' }}
						className={styles.labelContainerInput}
					>
						<Fragment>
							{labelComponent ? labelComponent : label}
							{isRequired && (
								<label className={styles.labelRequired}>*</label>
							)}
							{tooltipText && (
								<label style={{ color: 'red' }}>
									<HtmlTooltip
										title={
											<React.Fragment>
												<Typography
													color="inherit"
													sx={{
														fontFamily: 'Geomanist, Regular',
														fontSize: '14px',
														color: 'black',
													}}
												>
													{tooltipText}
												</Typography>
											</React.Fragment>
										}
										placement="right"
										arrow
										TransitionComponent={Fade}
										TransitionProps={{ timeout: 600 }}
									>
										<Error style={{ fontSize: 20, marginLeft: 15 }} />
									</HtmlTooltip>
								</label>
							)}
						</Fragment>
					</span>
				}
				className={styles.containerInput}
				labelPlacement="top"
				control={
					<TextField
						inputProps={{
							...inputProps,
							'data-testid': { id },
							maxLength: maxLength,
						}}
						InputProps={{
							...InputProps,
							endAdornment: type === 'password' && (
								<InputAdornment position="end">
									<IconButton
										aria-label="toggle password visibility"
										onClick={handleClickShowPwd}
										onMouseDown={handleMouseDownPwd}
									>
										{showPwd ? <Visibility /> : <VisibilityOff />}
									</IconButton>
								</InputAdornment>
							),
							startAdornment,
						}}
						id={id}
						name={name}
						type={
							type === 'password' ? (showPwd ? 'text' : 'password') : 'text'
						}
						variant="outlined"
						margin="dense"
						size={size || 'small'}
						fullWidth
						sx={sx}
						className={styles.textField}
						value={value}
						onChange={onChange}
						onBlur={onBlur}
						onFocus={onFocus}
						onKeyDown={onKeyDown}
						placeholder={placeholder}
						onPaste={(e) => {
							if (noPaste) {
								e.preventDefault();
								return false;
							}
						}}
						onCopy={(e) => {
							if (noCopy) {
								e.preventDefault();
								return false;
							}
						}}
						disabled={disabled}
						select={select}
					/>
				}
			/>
			{helperText && (
				<CustomAlert id={id} show={true} severity="error" message={helperText} />
			)}
		</div>
	);
};

export default TextInput;
