import { useState, useRef, useCallback, useEffect } from 'react';
import { ISelectOption } from './select-multiple.interface';
import { useOnClickOutside } from '../hooks/useOnClickOutside';

interface UseSelectMultipleParams {
	afterSelect?: (selectedOptions: ISelectOption[]) => void;
	selectedTexts?: string[];
	options: ISelectOption[];
}

export function useSelectMultiple({
	afterSelect,
	selectedTexts = [],
	options = [],
}: UseSelectMultipleParams) {
	const ref = useRef<HTMLDivElement>(null);

	// Estado interno con las opciones seleccionadas
	const [values, setValues] = useState<ISelectOption[]>([]);
	// Estado para mostrar los nombres de las opciones seleccionadas
	const [buttonValues, setButtonValues] = useState<string[]>([]);
	const [active, setActive] = useState(false);

	// Efecto para "sincronizar" con el prop selectedTexts
	useEffect(() => {
		// Filtra de las opciones totales solo aquellas que estén en selectedTexts
		const newValues = options.filter(
			(opt) => selectedTexts.includes(opt.value) // O "opt.id", depende de tu implementación
		);
		setValues(newValues);
		setButtonValues(newValues.map((v) => v.nombre));
	}, [selectedTexts, options]);

	const selectClick = useCallback(() => {
		setActive((prev) => !prev);
	}, []);

	const selected = useCallback(
		(option: ISelectOption) => {
			const exists = values.some((v) => v.value === option.value);
			let newValues: ISelectOption[];

			if (exists) {
				newValues = values.filter((v) => v.value !== option.value);
			} else {
				newValues = [...values, option];
			}

			setValues(newValues);
			setButtonValues(newValues.map((v) => v.nombre));

			// Notifica al padre
			afterSelect?.(newValues);
		},
		[values, afterSelect]
	);

	const handleClickOutside = () => {
		setActive(false);
	};
	useOnClickOutside(ref, handleClickOutside);

	return { ref, values, buttonValues, active, selectClick, selected };
}
