import { useLayout } from '../../../hooks/useLayout';
import backgroundHero from '../../../assets/imgs/hero/tramites.jpg';
import { useFreeNoticeDownload } from '../context/useFreeNoticeDownloadContext';

export function useFreeNoticeDownloadMain() {
	const { rfc } = useFreeNoticeDownload();

	useLayout({
		title: `RFC: ${rfc || ''}`,
		breadcrumbs: ['Consultas', 'Servicio de descarga de avisos'],
		background: backgroundHero,
		about: process.env.REACT_APP_URL_NOTICE_DOWNLOAD_ABOUT,
		guide: process.env.REACT_APP_URL_NOTICE_DOWNLOAD_GUIDE,
	});
}
