import useModal from '../../../hooks/useModal';
import { useApiRequest } from '../../../hooks/useApiRequest';
import { useFreeNoticeDownload } from '../context/useFreeNoticeDownloadContext';
import { useLoading } from '../../../hooks/useLoading';
import { useEffect } from 'react';

export function useGetRFCbyNRPService() {
	const { openErrorModal } = useModal();
	const { loadingFalseTwo } = useLoading();
	const { setIsLoadingTable } = useFreeNoticeDownload();
	const { execute, value, status } = useApiRequest<unknown, any>({
		path: 'servicio-gratuito/consultar-rfc-por-nrp',
	});

	useEffect(() => {
		execute();
	}, []);

	useEffect(() => {
		if (status === 'success') {
			if (value?.code === '0000') {
				//console.log(value);
			}
			if (value?.code === '0001') {
				//console.log(value);
			}
			setIsLoadingTable(false);
			loadingFalseTwo();
		} else if (status === 'error') {
			openErrorModal(value?.message);
			setIsLoadingTable(false);
			loadingFalseTwo();
		}
	}, [status]);

	return {
		value,
		status,
	};
}
