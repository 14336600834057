import { Fragment, useEffect } from 'react';
import { Grid } from '@mui/material';
import PaperLogin from '../../components/paper-login.component';
import Information from '../../components/information.component';
import { Footer } from '../../components';

import styles from './styles/styles.module.css';

import { useActivaCuenta } from './hooks/useActivaCuenta';
import { RESPONSE_CODES } from '../../config/response-codes';
import { SuccessMessage } from './components/success-message.component';
import { ErrorMessage } from './components/error-message.component';

const AccountActivation = () => {
	const { handleActiveAccount, status, valueLogin } = useActivaCuenta();
	useEffect(() => {
		handleActiveAccount();
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<Fragment>
			<Grid className={styles.subContainer} container spacing={3}>
				<Grid
					className={styles.mainContainerForm}
					item
					xs={12}
					sm={12}
					md={12}
					lg={12}
				>
					<PaperLogin>
						{status === 'success' &&
						valueLogin?.code === RESPONSE_CODES.SUCCESSFUL ? (
							<SuccessMessage />
						) : (status === 'error' || status === 'success') &&
						  valueLogin?.code !== RESPONSE_CODES.SUCCESSFUL ? (
							<ErrorMessage />
						) : (
							''
						)}
					</PaperLogin>
				</Grid>
			</Grid>
			<Information />
			<Footer />
		</Fragment>
	);
};

export default AccountActivation;
