// Dependencies
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

// Resources
import { setLoading } from '../../../store/modules/loading';
import { useApiRequest } from '../../../hooks/useApiRequest';
import {
	ILoginCRMParams,
	ILoginCRMResponse,
	IModal,
} from '../interfaces/authCRM.interface';
import { RESPONSE_CODES } from '../../../config/response-codes';

// Components
import { createSearchParams, useNavigate } from 'react-router-dom';

export function useLoginCRM() {
	const queryParams = new URLSearchParams(window.location.search);
	const kValue = queryParams.get('encrypted') || '';
	const redirecvalue = queryParams.get('redirect');
	const [openModal, setOpenModal] = useState(false);
	const [data] = useState<ILoginCRMParams>({
		encrypted: kValue!,
	});
	const [modal, setModal] = useState<IModal>({
		title: '',
		open: false,
		body: '',
		type: '',
		labelButton: '',
	});

	const dispatch = useDispatch();
	const navigate = useNavigate();

	const {
		execute,
		value: valueLogin,
		status,
	} = useApiRequest<ILoginCRMParams, ILoginCRMResponse>({
		path: 'logincrm/authCRM',
		baseURL: process.env.REACT_APP_API_URL,
	});

	useEffect(() => {
		if (redirecvalue === undefined || redirecvalue === null) {
			try {
				navigate({
					pathname: '/external-login',
					search: createSearchParams({
						encrypted: kValue!,
						redirect: 'true',
					}).toString(),
				});
				handleOnSubmit();
			} catch (err) {
				//console.log(err);
			}
		}
	});

	useEffect(() => {
		handleValidateLogin();
	}, [valueLogin?.code, status]); // eslint-disable-line react-hooks/exhaustive-deps

	const handleValidateLogin = () => {
		dispatch(setLoading({ loading: true }));
		if (status === 'success' && valueLogin?.code === RESPONSE_CODES.SUCCESSFUL) {
			dispatch(setLoading({ loading: false }));
			sessionStorage.setItem('userData', JSON.stringify(valueLogin.data));
			navigate('inicio');
		} else if (
			status === 'error' &&
			valueLogin?.code === '' &&
			valueLogin?.internalCode === '' &&
			valueLogin.data === null
		) {
			setOpenModal(true);
			setModal({
				open: true,
				body: valueLogin?.message,
				type: 'error',
			});
			dispatch(setLoading({ loading: false }));
		} else if (
			(status === 'success' || status === 'error') &&
			valueLogin?.code !== RESPONSE_CODES.SUCCESSFUL
		) {
			dispatch(setLoading({ loading: false }));
			sessionStorage.removeItem('key');
			hasLoginError();
		}
	};

	const handleOnSubmit = () => {
		execute({ ...data });
	};
	const hasLoginError = () => {
		if (valueLogin?.code === undefined || valueLogin?.code === null) {
			setOpenModal(true);
			setModal({
				open: true,
				body: 'Por el momento los servicios no se encuentran disponibles. Intenta más tarde.',
				type: 'error',
			});
			dispatch(setLoading({ loading: false }));
		} else if (valueLogin?.code !== '0000') {
			setOpenModal(true);
			setModal({
				open: true,
				body: valueLogin?.message,
				type: 'error',
				labelButton: 'Regresar a la página principal',
			});
		}
	};
	return {
		valueLogin,
		openModal,
		status,
		setOpenModal,
		modal,
	};
}
