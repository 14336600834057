import { FreeNoticeDownloadProvider } from './context/free-notice-download.context';
import { FreeNoticeDownloadServicePage } from './free-notice-download.page';

export default function FreeNoticeDownloadService() {
	return (
		<FreeNoticeDownloadProvider>
			<FreeNoticeDownloadServicePage />
		</FreeNoticeDownloadProvider>
	);
}
