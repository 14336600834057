import { tabsEle, TABS } from '../constants/tabs.constants';
import { NoticeInquiry } from './notice-inquiry';
import { InputGenerator } from './input-generator';
import { Tabs } from '../../../components';
import { useFreeNoticeDownloadTabs } from '../hooks/useFreeNoticeDownloadTabs';

export function FreeNoticeDownloadTabs() {
	const { currentTab, setCurrentTab } = useFreeNoticeDownloadTabs();

	return (
		<Tabs
			tabs={tabsEle}
			active={currentTab}
			containerPosition={TABS.NOTICE}
			onChangeTab={(value) => setCurrentTab(value)}
		>
			<div>
				{currentTab !== TABS.INPUT_GENERATOR && <NoticeInquiry />}
				{currentTab === TABS.INPUT_GENERATOR && <InputGenerator />}
			</div>
		</Tabs>
	);
}
