export const downloadIndividualAdapter = (data: any, rfc: string) => {
	return {
		folioAviso: data.ID_NMRO_AVSO,
		rfcEmpresa: rfc,
		nrp: data.CV_NRP,
		razonSocial: data.TX_RZON_SCAL,
		domEmpresa: data.TX_DMCO_EMPA,
		nss: data.CV_NSS,
		nombreTrab: data.TX_NMBE_TRBR,
		rfcTrabajador: data.CV_RFC_TRBR,
		curpTrabajador: data.CV_CURP_TRBR,
		numCredito: data.CV_NMRO_CRDO,
		fechCreaAviso: data.FH_FCHA_CRCN_AVSO,
		fechOtorgamiento: data.FH_FCHA_OTRO_CRDO,
		tipoAviso: data.CV_TIPO_AVSO,
		tipoDescuento: data.CV_TIPO_DSCO,
		facDescuento: data.IM_FCTR_DSCO_ACTL,
		monDescuento: data.IM_MNTO_DSCO,
		//data.CV_ESTS_CRDO
		tipoCredito: data.CV_TIPO_CRDO,
		nssUnificado: data.CV_NSS_UNFO,
		fechaUltimoAviso: data.FH_FCHA_ULTO_AVSO,
		fechNotLinea: data.FH_FCHA_NTFN_LNEA,
		cadenaOriginal: data.TX_CDNA_ORGL,
		selloDigital: data.TX_SLLO_DGTL,
	};
};
