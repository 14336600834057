import { configureStore } from '@reduxjs/toolkit';
import userReducer from './modules/user';
import loadingReducer from './modules/loading';
import questionsReducer from './modules/questions';
import layoutReducer from './modules/layout';
import loadingRequest from './modules/loadingRequest';
import diezPlusReducer from './modules/diezPlus';
import loadingTwoReducer from './modules/loadingTwo';
import tabsSIReducer from './modules/tabsSI';
import idSistema from './modules/idSistema';
import routeModReducer from './modules/routeModified';
import rfcReducer from './modules/rfcCSF';
import nameReducer from './modules/nameEfirma';
import rutaCSFReducer from './modules/rutaCSF';
import modalReducer from './modules/modal';
import modalErrorSlice from './modules/modal-error/modal-error.slice';
import loadingThreeSlice from './modules/loadingThree';

const store = configureStore({
	reducer: {
		users: userReducer,
		loading: loadingReducer,
		questions: questionsReducer,
		layout: layoutReducer,
		loadingRequest: loadingRequest,
		diezPlus: diezPlusReducer,
		loadingTwo: loadingTwoReducer,
		tabActive: tabsSIReducer,
		idSistema: idSistema,
		routeMod: routeModReducer,
		rfcCSF: rfcReducer,
		nameEfirma: nameReducer,
		rutaCSF: rutaCSFReducer,
		modal: modalReducer,
		modalError: modalErrorSlice,
		loadingThree: loadingThreeSlice,
	},
});

export default store;
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
