import CustomButton from '../../../../components/button.component';
import { useDownloadForm } from '../../pages/input-generator/components/download-form/hooks/useDownloadForm';
import styles from './export-buttons.module.css';
import { useFreeNoticeDownload } from '../../context/useFreeNoticeDownloadContext';
import { useWebSocketFileDownloadSingleZIP } from '../../hooks/useDownloadSocketv4';
import { adaptFormValues } from '../../adapters/download-masive.adapter';
import { TABS } from '../../constants/tabs.constants';

const noticeType = (currentTab: number): string => {
	switch (currentTab) {
		case TABS.NOTICE:
			return '0';
		case TABS.ACCREDITED:
			return '1';
		case TABS.SUSPENDED:
			return '2';
		default:
			return '0';
	}
};

export function ExportButtons() {
	const { downloadDocument } = useDownloadForm();
	const { dataTable, formValues, rfc, folioAvisoList, currentTab } =
		useFreeNoticeDownload();
	const { requestFile } = useWebSocketFileDownloadSingleZIP();

	const handleGenerateClick = () => {
		const baseWsUrl = process.env.REACT_APP_URL_NOTICE_WEBSOCKET_MQ;
		const params = adaptFormValues(
			{
				...formValues,
				rfcEmpresa: rfc || '',
				folioAvisoList: folioAvisoList,
				tipoConsulta: noticeType(currentTab),
			},
			0
		);
		const url = new URL(baseWsUrl!);

		Object.entries(params).forEach(([key, value]) => {
			if (value !== undefined && value !== null && value !== '') {
				url.searchParams.set(key, String(value));
			}
		});

		const data = JSON.parse(sessionStorage.getItem('userData')!);
		const token = data?.token || '';
		requestFile(url.toString(), token, params);
	};

	const handleExportXLS = () => {
		downloadDocument('xls');
	};

	return (
		<div className={styles.buttonsFooter}>
			<CustomButton
				variant="outlined"
				styles={{ maxWidth: 'fit-content', padding: '0 40px' }}
				onClick={handleGenerateClick}
				disabled={dataTable.length === 0}
			>
				Generar representación impresa
			</CustomButton>

			<CustomButton
				variant="solid"
				onClick={handleExportXLS}
				styles={{ maxWidth: 'fit-content', padding: '0 40px' }}
				disabled={dataTable.length === 0}
			>
				Exportar archivo .xls
			</CustomButton>
		</div>
	);
}
