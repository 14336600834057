import { useDispatch } from 'react-redux';
import { openModal, closeModal } from '../store/modules/modal';
import { AppDispatch } from '../store';
import { INTERNAL_MESSAGES } from '../config/response-codes';

const useModal = () => {
	const dispatch = useDispatch<AppDispatch>();

	const openErrorModal = (message?: string) => {
		dispatch(
			openModal({
				message: message ? message : INTERNAL_MESSAGES.ERROR_MESSAGE,
				type: 'error',
				button: 'Aceptar',
			})
		);
	};

	const closeModalHandler = () => {
		dispatch(closeModal());
	};

	return { openErrorModal, closeModalHandler };
};

export default useModal;
