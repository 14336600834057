import { Fragment } from 'react';
import CustomButton from '../../../components/button.component';
import { useNavigate } from 'react-router-dom';
import styles from '../styles/styles.module.css';

export const SuccessMessage = () => {
	const navigate = useNavigate();

	return (
		<Fragment>
			<h4 style={{ marginBottom: '40px' }}>Activación de tu cuenta</h4>
			<span className={styles.txtIntro}>
				<strong>¡Tu cuenta ha sido activada!</strong> Entra <a href="/">aquí</a> y
				comienza a disfrutar de los beneficios que te ofrece el Portal
				Empresarial.
			</span>
			<CustomButton
				onClick={() => navigate('/')}
				variant="outlined"
				label="Regresar a la página principal"
				styles={{ width: '50%', marginTop: '40px' }}
			/>
		</Fragment>
	);
};
