import { useState, useEffect, ChangeEvent } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { Label } from './label.component';
import { AlertInput } from './alert-input.component';
import 'dayjs/locale/es-mx';

// styles
import style from './styles/input-date-styles.component.module.css';

// assets
import arrowDown from '../assets/svg/arrow-down-s-fill.svg';
import arrowUp from '../assets/svg/arrow-up-s-fill.svg';

interface IInputDate {
	label?: string;
	name: string;
	required?: boolean;
	value: string | undefined; // Recibes el value como string
	onChange?: (newValue: Dayjs | null | undefined) => void;
	error?: { valid: boolean; msg: string };
	disabled?: boolean;
	onChangeDate?: (event: ChangeEvent<HTMLInputElement>) => void;
	minDate?: Dayjs;
	maxDate?: Dayjs;
}

export default function Datepicker({
	label,
	name,
	required,
	value,
	error,
	onChange,
	disabled,
	onChangeDate,
	minDate,
	maxDate,
}: IInputDate) {
	const [valueButton, setValueButton] = useState<string>('');
	const [isOpen, setIsOpen] = useState<boolean>(false);

	// Convertir el valor string a Dayjs
	const dateValue = value ? dayjs(value, 'YYYY-MM-DD') : null;

	useEffect(() => {
		setValueButton(dateValue?.format('DD - MM - YYYY') || '');
	}, [dateValue]);

	const onChangeValue = (date: dayjs.Dayjs | null) => {
		const firstDate = date ? date?.format('YYYY-MM-DD') : '';

		const event = {
			target: {
				name: name || '',
				value: firstDate,
			},
		} as ChangeEvent<HTMLInputElement>;

		if (onChangeDate) onChangeDate(event);
	};

	return (
		<LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="es-mx">
			<DatePicker
				value={dateValue}
				onChange={(newValue) => {
					if (onChange) onChange(newValue);
					if (onChangeValue) onChangeValue(newValue);
					setValueButton(newValue?.format('DD - MM - YYYY') || '');
				}}
				minDate={minDate}
				maxDate={maxDate}
				open={isOpen}
				showDaysOutsideCurrentMonth={true}
				onClose={() => setIsOpen(false)}
				reduceAnimations={true}
				PaperProps={dateTimePaperPropsStyles}
				renderInput={({ inputRef, inputProps }) => (
					<div className={style.content}>
						<Label label={label} name={name} required={required} />
						<div className={style.inputContent}>
							<button
								className={`${style.input} ${
									valueButton === '' ? style.empty : style.notEmpty
								}`}
								type="button"
								onClick={() => setIsOpen((isOpen) => !isOpen)}
								disabled={disabled}
							>
								{valueButton}
								<img
									src={isOpen ? arrowUp : arrowDown}
									alt="icono"
									className={style.icon}
								/>
							</button>
							<input
								ref={inputRef}
								{...inputProps}
								style={{
									opacity: 0,
									height: 0,
									width: '100%',
									position: 'absolute',
								}}
							/>
						</div>
						{error && (
							<AlertInput
								name={name}
								valid={error.valid}
								message={error.msg}
							/>
						)}
					</div>
				)}
			/>
		</LocalizationProvider>
	);
}

const dateTimePaperPropsStyles = {
	sx: {
		'.MuiPickersDay-root': {
			border: '1px solid #C6C6C6',
			borderRadius: 'unset',
			background: '#FBFBFB',
			fontWeight: '400',
			fontSize: '18px',
			lineHeight: '24px',
			color: '#333333',
			width: '68px',
			transition: 'background.1500s',
			margin: '0px',
		},
		'.MuiDayPicker-weekDayLabel': {
			border: '1px solid #C6C6C6',
			borderRadius: 'unset',
			background: '#FBFBFB',
			fontWeight: '700',
			fontSize: '18px',
			lineHeight: '24px',
			color: '#333333',
			width: '68px',
			margin: '0px',
		},
		'.MuiPickersDay-dayOutsideMonth': {
			color: '#C6C6C6',
		},
		'.MuiPickersDay-root.Mui-disabled': {
			color: '#C6C6C6',
		},
		'.MuiPickersPopper-paper': {
			borderRadius: '0px',
		},
		'.MuiDayPicker-slideTransition': {
			minHeight: '0px',
		},
		'.MuiDayPicker-weekContainer': {
			margin: '0px',
		},
		'.MuiPickersCalendarHeader-root': {
			margin: '0px',
			padding: '16px',
			background: '#E6E6E6',
			maxHeight: '70px',
			position: 'relative',
		},
		'.MuiPickersDay-root:hover': {
			backgroundColor: '#293990',
			color: 'white',
			fontWeight: '500',
			transition: 'background.1500s',
		},
		'.MuiSvgIcon-root': {
			color: '#293990',
		},
		'.MuiPickersCalendarHeader-switchViewIcon': {
			display: 'none',
		},
		'.MuiPickersArrowSwitcher-root': {
			justifyContent: 'space-between',
			width: '100%',
		},
		'.MuiPickersCalendarHeader-labelContainer': {
			position: 'absolute',
			top: '50%',
			left: '50%',
			webkitTransform: 'translateX(-50%) translateY(-50%)',
			transform: 'translateX(-50%) translateY(-50%)',
		},
		'.MuiPickersCalendarHeader-label': {
			marginLeft: '0',
			fontWeight: '500',
			fontSize: '18px',
			lineHeight: '24px',
			textTransform: 'capitalize',
		},
		'.MuiPickersDay-root.Mui-selected:hover': {
			backgroundColor: '#293990',
			color: 'white',
			fontWeight: '500',
			transition: 'background.1500s',
		},
		'.MuiPickersDay-root.Mui-selected': {
			backgroundColor: '#293990',
			color: 'white',
			fontWeight: '500',
		},
		'.MuiPickersDay-root:focus.Mui-selected': {
			backgroundColor: '#293990',
			color: 'white',
			fontWeight: '500',
		},
		'.MuiPickersDay-root:not(.Mui-selected)': {
			border: '1px solid #C6C6C6',
		},
	},
};
