//import jwt_decode from 'jwt-decode';

export const getProfile = () => {
	if (sessionStorage.getItem('userData')) {
		const data = JSON.parse(sessionStorage.getItem('userData')!);
		const base64Url = data.token.split('.')[1];
		const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
		const jsonPayload = decodeURIComponent(
			window
				.atob(base64)
				.split('')
				.map(function (c) {
					return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
				})
				.join('')
		);
		return JSON.parse(jsonPayload);
	}
	return null;
};

//decode the token more 2 bytes
