import style from './styles.module.css';
import { Label } from '../../../../components/label.component';
import { AlertInput } from '../../../../components/alert-input.component';
import { useSelect } from './useSelect';

import arrowDown from '../../../../assets/svg/arrow-down-s-fill.svg';
import arrowUp from '../../../../assets/svg/arrow-up-s-fill.svg';
import { ISelectTwo } from './SelectTwo.types';

export default function SelectTwo({
	label,
	required,
	options,
	name,
	afterSelect,
	error,
	errorCallback,
	disabled,
	selectedValue,
}: ISelectTwo) {
	const { ref, value, buttonValue, active, selectClick, selected } = useSelect({
		afterSelect,
		errorCallback,
		selectedValue,
		options,
	});

	const handleSelectClick = () => {
		if (!disabled) {
			selectClick();
		}
	};

	return (
		<div className={style.content}>
			<Label label={label} name={name} required={required} />
			<div ref={ref} className={style.contentSelect}>
				<button
					className={`${style.select} ${value ? style.notEmpty : style.empty} ${
						disabled ? style.disabled : ''
					}`}
					onClick={handleSelectClick}
					type="button"
					disabled={disabled}
				>
					{buttonValue || 'Seleccione...'}
					<img
						src={active ? arrowUp : arrowDown}
						alt="icono"
						className={style.icon}
					/>
				</button>
				{active && !disabled && (
					<div className={style.options}>
						{options.map((option) => (
							<button
								className={style.option}
								key={option.value}
								onClick={() => selected(option)}
								type="button"
							>
								{option.nombre}
							</button>
						))}
					</div>
				)}
				{error && (
					<AlertInput name={name} valid={error.valid} message={error.msg} />
				)}
				<input
					name={name}
					value={value ? value.value : ''}
					style={{ display: 'none' }}
					readOnly
				/>
			</div>
		</div>
	);
}
