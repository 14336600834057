import { Button, Input } from '../../../../../../components';
import { useEffect, useState } from 'react';
import styles from '../../styles.module.css';
import { useDownloadForm } from './hooks/useDownloadForm';
import CustomButton from '../../../../../../components/button.component';
import { useMassiveNoticeDownload } from '../../../../hooks/useMassiveNoticeDownload';
import { useFreeNoticeDownload } from '../../../../context/useFreeNoticeDownloadContext';
import SelectTwo from '../../../../components/select/SelectTwo';

interface IError {
	valid: boolean;
	msg: string;
}

export function DownloadForm() {
	const [selectedFormat, setSelectedFormat] = useState<string>('');
	const [separator, setSeparator] = useState<string>('');
	const [customSeparator, setCustomSeparator] = useState<string>('');
	const [customSeparatorError, setCustomSeparatorError] = useState<IError | undefined>({
		valid: false,
		msg: '',
	});

	const { downloadDocument } = useDownloadForm();
	const { executeMassiveNotice } = useMassiveNoticeDownload();
	const { dataTable } = useFreeNoticeDownload();

	useEffect(() => {
		if (dataTable.length === 0) {
			setSelectedFormat('');
			setSeparator('');
			setCustomSeparator('');
			setCustomSeparatorError(undefined);
		}
	}, [dataTable.length]);

	const handleCustomSeparatorChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const { value } = event.target;

		const onlySpecialCharPattern = /^[^a-zA-Z0-9\s]$/;

		if (value === '' || (value.length === 1 && onlySpecialCharPattern.test(value))) {
			setCustomSeparator(value);
			setCustomSeparatorError(undefined);
		} else {
			setCustomSeparator(value);
			setCustomSeparatorError({
				valid: false,
				msg: 'Sólo se permite un (1) carácter especial.',
			});
		}
	};

	const handleSubmit = () => {
		if (selectedFormat === '') return;

		if (selectedFormat === 'txt') {
			if (separator === '') {
				alert('Por favor, selecciona un carácter de separación');
				return;
			}

			if (separator === 'otro') {
				if (!customSeparator || customSeparatorError) {
					alert('Debe ingresar un carácter especial válido para continuar.');
					return;
				}
				downloadDocument(selectedFormat, customSeparator);
				return;
			}
		}

		downloadDocument(selectedFormat, separator);
	};

	return (
		<div className={styles.downloadContent}>
			<div style={{ maxWidth: '300px', width: '100%' }}>
				<SelectTwo
					label="Formato de salida"
					options={[
						{ value: 'xls', nombre: 'XLS' },
						{ value: 'xml', nombre: 'XML' },
						{ value: 'json', nombre: 'JSON' },
						{ value: 'txt', nombre: 'TXT' },
					]}
					name="format"
					afterSelect={(value) => setSelectedFormat(value.value)}
					selectedValue={selectedFormat}
					disabled={dataTable.length === 0}
				/>
			</div>

			{selectedFormat === 'txt' && (
				<div
					style={{
						maxWidth: '400px',
						width: '100%',
					}}
					className={styles.downloadContent}
				>
					<SelectTwo
						label="Caractér de separación"
						options={[
							{ value: '|', nombre: '|' },
							{ value: ',', nombre: ',' },
							{ value: 'tabulador', nombre: 'Tabulador' },
							{ value: 'otro', nombre: 'Otro' },
						]}
						name="Separator"
						afterSelect={(value) => {
							setSeparator(value.value);
							setCustomSeparator('');
							setCustomSeparatorError(undefined);
						}}
						disabled={dataTable.length === 0}
						selectedValue={separator}
					/>
					{separator === 'otro' && (
						<Input
							label="Separador"
							name="otro"
							maxLength={1}
							style={{ maxWidth: '120px' }}
							onChange={handleCustomSeparatorChange}
							error={customSeparatorError}
							value={customSeparator}
						/>
					)}
				</div>
			)}

			<Button
				label="Generar insumo"
				styles={{
					maxWidth: '200px',
				}}
				disabled={
					!selectedFormat ||
					dataTable.length === 0 ||
					(selectedFormat === 'txt' &&
						separator === 'otro' &&
						(!customSeparator || !!customSeparatorError))
				}
				onClick={handleSubmit}
			/>

			<CustomButton
				variant="outlined"
				styles={{ maxWidth: 'fit-content', padding: '0 40px' }}
				onClick={() => executeMassiveNotice()}
				disabled={dataTable.length === 0}
			>
				Generar representación impresa
			</CustomButton>
		</div>
	);
}
