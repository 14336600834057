import { useFreeNoticeDownload } from '../../context/useFreeNoticeDownloadContext';
import { DownloadForm } from './components/download-form/download-form.component';
import { FilterForm } from '../../components/filter-form/header.component';
import { tableHeader } from '../../constants/table.constants';

import styles from './styles.module.css';
import { TotalTable } from '../../components/total-table/total-table.component';
import DataTable from '../../components/table/table';
import { useEffect } from 'react';

type Option = {
	value: number;
	id: string;
	name: string;
};

export function InputGenerator() {
	const { dataTable, selectedOptions, setHeadersMap, headersMap } =
		useFreeNoticeDownload();

	useEffect(() => {
		let newResolvedHeaders = selectedOptions
			.map((option) => {
				return tableHeader.find(
					(header) => Number(header.value) === Number(option.id)
				);
			})
			.filter((header): header is Option => header !== undefined);

		if (newResolvedHeaders.length === 0) {
			newResolvedHeaders = tableHeader;
		}

		setHeadersMap(newResolvedHeaders);
	}, [selectedOptions]);

	return (
		<div className={styles.container}>
			<FilterForm />
			<DownloadForm />

			<TotalTable />
			<div style={{ width: '100%' }}>
				<p>
					<strong>
						Puedes editar los títulos de las columnas haciendo doble clic
						sobre ellos.
					</strong>
				</p>
			</div>

			<DataTable
				headers={headersMap}
				data={dataTable}
				rowsPerPage={5}
				align={'center'}
				setHeaders={setHeadersMap}
			/>
		</div>
	);
}
