import { MetricData } from '../context/free-notice-download.interface';

export const labelMetrics: Record<keyof MetricData, string> = {
	TTAL_NRP: 'Total de NRP:',
	TTAL_AVSS: 'Total de avisos:',
	TTAL_CRDS: 'Total de créditos:',
	TTAL_NSS: 'Total NSS:',
	RC_TTAL_AVSS_RTNN: 'Aviso retención:',
	RC_TTAL_AVSS_MDCN: 'Aviso modificación:',
	RC_TTAL_AVSS_SSPN: 'Avisos suspensión:',
	TTAL_RGTS: 'Total de registros:',
};
