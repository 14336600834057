import { useContext } from 'react';
import { FreeNoticeDownloadContext } from './free-notice-download.context';

export function useFreeNoticeDownload() {
	const context = useContext(FreeNoticeDownloadContext);
	if (!context) {
		throw new Error('useLoginContext must be used within a LoginProvider');
	}
	return context;
}
