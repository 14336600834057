import { useEffect } from 'react';
import { useActiveAccount } from './hooks/useActiveAccount';
import { useNavigate } from 'react-router-dom';

const ActivateAccount = () => {
	const { valueLogin, status, RESPONSE_CODES } = useActiveAccount();
	const navigate = useNavigate();

	useEffect(() => {
		if (status === 'success') {
			if (valueLogin?.code === RESPONSE_CODES.SUCCESSFUL) {
				navigate('/?register=true');
			} else {
				navigate('/?register=false');
			}
		}
	}, [status, valueLogin, RESPONSE_CODES, navigate]);

	return <div>cargando...</div>;
};

export default ActivateAccount;
