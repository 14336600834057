import React from 'react';
import styles from './styles/info-alert-styles.component.module.css';
import LinearProgress from '@mui/material/LinearProgress';
import Stack from '@mui/material/Stack';
import { useSelector } from 'react-redux';
import { RootState } from '../store';

const InfoAlert: React.FC = () => {
	const { loading, message } = useSelector((state: RootState) => state.loadingThree);

	if (!loading) return null;
	return (
		<div className={styles.alertContainer}>
			<Stack sx={{ width: '100%', color: 'grey.500' }} spacing={2}>
				<LinearProgress color="success" />
			</Stack>

			<div className={styles.alertContent}>
				<span>{message}</span>
			</div>
		</div>
	);
};

export default InfoAlert;
