import { useState, useRef, useEffect } from 'react';
import { useOnClickOutside } from '../../../../components/hooks/useOnClickOutside';

interface IOption {
	nombre: string;
	value: string;
}

export function useSelect({
	afterSelect,
	errorCallback,
	selectedValue,
	options = [],
}: {
	afterSelect?: (option: IOption) => void;
	errorCallback?: (active: boolean) => void;
	selectedValue?: string;
	options: IOption[];
}) {
	const [active, setActive] = useState(false);
	const [value, setValue] = useState<IOption | null>(null);
	const [buttonValue, setButtonValue] = useState('');

	const ref = useRef<HTMLDivElement>(null);

	// Cuando cambie selectedValue desde fuera, actualizamos nuestro estado interno.
	useEffect(() => {
		if (!selectedValue) {
			// Si llega vacío o undefined, reseteamos
			setValue(null);
			setButtonValue(''); // o texto por defecto
		} else {
			// Buscamos la opción que coincida con ese value
			const match = options.find((opt) => opt.value === selectedValue);
			if (match) {
				setValue(match);
				setButtonValue(match.nombre);
			} else {
				// Si no coincide, lo manejas como quieras (ej: reset)
				setValue(null);
				setButtonValue('');
			}
		}
	}, [selectedValue, options]);

	const selectClick = () => {
		setActive(!active);
		errorCallback?.(!active);
	};

	const handleClickOutside = () => {
		setActive(false);
	};

	const selected = (option: IOption) => {
		setValue(option);
		setButtonValue(option.nombre);
		setActive(false);
		afterSelect?.(option);
	};

	useOnClickOutside(ref, handleClickOutside);

	return {
		ref,
		active,
		value,
		buttonValue,
		selectClick,
		selected,
	};
}
