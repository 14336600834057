import { FormValues } from '../interfaces/form.interfaces';

interface ExecuteParams {
	rfcEmpresa: string;
	ultimoAviso: string;
	tipoConsulta: string;
	pagina?: number;
	nrp?: string;
	nss?: string;
	numeroCredito?: string;
	tipoAviso?: string;
	fechaCreacionInicial?: string;
	fechaCreacionFinal?: string;
	folioAvisoList?: { folioAviso: string }[];
}

interface FormValuesAdapter extends FormValues {
	rfcEmpresa: string;
	folioAvisoList: { folioAviso: string }[];
	tipoConsulta: string;
}

export const adaptFormValues = (
	formValues: FormValuesAdapter,
	page: number
): ExecuteParams => {
	const params: ExecuteParams = {
		rfcEmpresa: formValues.rfcEmpresa || '',
		tipoConsulta: formValues.tipoConsulta,
		ultimoAviso: formValues.IP_ULTO_AVSO,
		pagina: page,
	};

	if (formValues.folioAvisoList && formValues.folioAvisoList.length > 0) {
		params.folioAvisoList = formValues.folioAvisoList;
	}

	if (formValues.IP_NRP && formValues.IP_NRP.trim() !== '') {
		params.nrp = formValues.IP_NRP;
	}

	if (formValues.IP_NSS && formValues.IP_NSS.trim() !== '') {
		params.nss = formValues.IP_NSS;
	}

	if (formValues.IP_NMRO_CRDO && formValues.IP_NMRO_CRDO.trim() !== '') {
		params.numeroCredito = formValues.IP_NMRO_CRDO;
	}

	if (formValues.IP_TIPO_AVSO && formValues.IP_TIPO_AVSO.trim() !== '') {
		params.tipoAviso = formValues.IP_TIPO_AVSO;
	}

	if (formValues.IP_FCHA_CRCN_INI && formValues.IP_FCHA_CRCN_INI.trim() !== '') {
		params.fechaCreacionInicial = formValues.IP_FCHA_CRCN_INI;
	}

	if (formValues.IP_FCHA_CRCN_FIN && formValues.IP_FCHA_CRCN_FIN.trim() !== '') {
		params.fechaCreacionFinal = formValues.IP_FCHA_CRCN_FIN;
	}

	return params;
};
