import { useCallback, useRef } from 'react';
import { useLoading } from '../../../hooks/useLoading';
import useModal from '../../../hooks/useModal';
import { useLogService } from './useLogService';

function downloadBlob(blob: Blob, fileName: string) {
	const url = URL.createObjectURL(blob);
	const a = document.createElement('a');
	a.href = url;
	a.download = fileName;
	a.style.display = 'none';
	document.body.appendChild(a);
	a.click();
	document.body.removeChild(a);
	URL.revokeObjectURL(url);
}

function concatenateArrayBuffers(buffers: ArrayBuffer[]): ArrayBuffer {
	const totalLength = buffers.reduce((sum, b) => sum + b.byteLength, 0);
	const temp = new Uint8Array(totalLength);
	let offset = 0;
	for (const buffer of buffers) {
		temp.set(new Uint8Array(buffer), offset);
		offset += buffer.byteLength;
	}
	return temp.buffer;
}

interface ExecuteParams {
	rfcEmpresa: string;
	ultimoAviso: string;
	tipoConsulta: string;
	pagina?: number;
	nrp?: string;
}

interface UseWebSocketFileDownloadReturn {
	requestFile: (wsUrl: string, token: string, params?: ExecuteParams) => void;
}

export function useWebSocketFileDownloadSingleZIP(): UseWebSocketFileDownloadReturn {
	const { loadingFalseThree, loadingTrueThree } = useLoading();
	const { openErrorModal } = useModal();
	const { saveLog } = useLogService();

	const binaryChunksRef = useRef<ArrayBuffer[]>([]);

	const requestFile = useCallback(
		(wsUrl: string, token: string, params?: ExecuteParams) => {
			const finalUrl = wsUrl.includes('?') ? `${wsUrl}` : `${wsUrl}`;

			binaryChunksRef.current = [];

			const ws = new WebSocket(finalUrl);
			ws.binaryType = 'arraybuffer';

			ws.onopen = () => {
				loadingTrueThree('Procesando');
				if (params) {
					ws.send(JSON.stringify(params));
					saveLog({
						type: 'Request',
						request: JSON.stringify(params),
						response: 'Procesando',
					});
				} else {
					ws.send('Iniciando descarga sin parámetros');
				}
			};

			ws.onmessage = (event: MessageEvent) => {
				if (event.data instanceof Blob) {
					const reader = new FileReader();
					reader.onload = () => {
						if (reader.result) {
							binaryChunksRef.current.push(reader.result as ArrayBuffer);
						}
					};
					reader.readAsArrayBuffer(event.data);
					return;
				}

				if (event.data instanceof ArrayBuffer) {
					binaryChunksRef.current.push(event.data);
					return;
				}

				try {
					const text = JSON.parse(event.data);
					loadingTrueThree(text.detalles);

					if (text.detalles === 'Cero avisos encontrados en TRM') {
						openErrorModal(text.detalles);
					}

					if (
						text.detalles ===
						'Fin del procesamiento para la creacion de Avisos en PDF'
					) {
						if (binaryChunksRef.current.length > 0) {
							const combined = concatenateArrayBuffers(
								binaryChunksRef.current
							);
							const blob = new Blob([combined], {
								type: 'application/zip',
							});
							downloadBlob(blob, `${params?.rfcEmpresa}.zip`);
						}
						saveLog({
							type: 'Response',
							request: JSON.stringify(params),
							response: text.detalles,
						});
						loadingFalseThree();
					}

					if (
						text.mensaje.includes(
							'Ya existe un proceso de MQ en curso ejecutado anteriormente para el RFC'
						)
					) {
						openErrorModal(text.mensaje);
						loadingFalseThree();
					}
				} catch (error) {
					openErrorModal();
				}
			};

			ws.onerror = (error) => {
				openErrorModal();
				saveLog({
					type: 'Error',
					request: JSON.stringify(params),
					response: JSON.stringify(error),
				});
			};

			ws.onclose = () => {};
		},
		[loadingFalseThree, loadingTrueThree]
	);

	return { requestFile };
}
