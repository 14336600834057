export interface FormValues {
	IP_LIST_CLMS: string;
	IP_CLSN_AVSO: string;
	IP_ULTO_AVSO: string;
	IP_NRP: string | undefined;
	IP_NSS: string | undefined;
	IP_NMRO_CRDO?: string | undefined;
	IP_TIPO_AVSO?: string | undefined;
	IP_FCHA_CRCN_INI: string;
	IP_FCHA_CRCN_FIN: string;
}

export const initialValues = {
	IP_LIST_CLMS: 'ALL',
	IP_CLSN_AVSO: '0',
	IP_ULTO_AVSO: '1',
	IP_NRP: '',
	IP_NSS: '',
	IP_NMRO_CRDO: '',
	IP_TIPO_AVSO: '',
	IP_FCHA_CRCN_INI: '',
	IP_FCHA_CRCN_FIN: '',
};
