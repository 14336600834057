import { ChangeEvent, useState, useEffect } from 'react';
import { FormValues, initialValues } from '../../../interfaces/form.interfaces';
import { useTableService } from '../../../hooks/useTableService';
import { useGeneralMetrics } from '../../../hooks/useGeneralMetrics';
import { useFreeNoticeDownload } from '../../../context/useFreeNoticeDownloadContext';
import { ISelectOption } from '../../../../../components/select-multiple/select-multiple.interface';

export interface Error {
	valid: boolean;
	msg: string;
}

export const initialError: Error = {
	valid: true,
	msg: '',
};

const generateInitialErrors = (values: FormValues): { [key: string]: Error } => {
	const errors: { [key: string]: Error } = {};
	Object.keys(values).forEach((key) => {
		errors[key] = initialError;
	});
	return errors;
};

export function useNoticeInquiryForm() {
	const {
		setCriteriaWarningModal,
		setServiceConfirmModal,
		currentTab,
		formValues,
		setFormValues,
		setDisabledInput,
		setMetrics,
		setDataTable,
		selectedOptions,
		setSelectedOptions,
	} = useFreeNoticeDownload();

	const [errors, setErrors] = useState<{ [key: string]: Error }>(
		generateInitialErrors(initialValues)
	);
	const [disabled, setDisabled] = useState(true);
	const { getTableService } = useTableService();
	const { getGeneralMetrics, status } = useGeneralMetrics();

	//const [selectedOptions, setSelectedOptions] = useState<Option[]>([]);

	const handleInputChange = (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
		const { name, value } = e.target;

		let cleanedValue = value;

		if (name === 'IP_NSS') {
			cleanedValue = value.replace(/[^0-9]/g, '');
		} else if (name === 'IP_NRP') {
			cleanedValue = value.replace(/[^a-zA-Z0-9]/g, '');
		} else if (name === 'IP_NMRO_CRDO') {
			cleanedValue = value.replace(/[^0-9]/g, '');
		}

		setFormValues((prevValues: FormValues) => ({
			...prevValues,
			[name]: cleanedValue.toLocaleUpperCase(),
		}));

		setErrors((prevErrors) => ({
			...prevErrors,
			[name]: validate(name, cleanedValue),
		}));
	};

	const validate = (name: string, value: string): Error => {
		let error = { valid: true, msg: '' };

		switch (name) {
			case 'IP_NRP':
				if (value.length !== 11 && value.length !== 0) {
					error = {
						valid: false,
						msg: 'El NRP ingresado no es válido. Verifique',
					};
				}
				break;
			case 'IP_NSS':
				if (value.length !== 11 && value.length !== 0) {
					error = {
						valid: false,
						msg: 'El NSS ingresado no es válido. Verifique',
					};
				}
				break;
			case 'IP_NMRO_CRDO':
				if (value.length !== 10 && value.length !== 0) {
					error = {
						valid: false,
						msg: 'El Número de crédito ingresado no es válido. Verifique',
					};
				}
				break;
			default:
				break;
		}

		return error;
	};

	/**
	 * Validación de fechas:
	 * - Verifica campos vacíos si alguno está lleno.
	 * - Verifica que la fecha inicio no sea mayor a la fecha fin.
	 * - Verifica que la fecha fin no sea menor a la fecha inicio.
	 */
	useEffect(() => {
		const tempErrors = { ...errors };
		const { IP_FCHA_CRCN_INI, IP_FCHA_CRCN_FIN } = formValues;

		// -- Validación de "fecha inicio" vacía si hay "fecha fin"
		if (IP_FCHA_CRCN_FIN !== '' && IP_FCHA_CRCN_INI === '') {
			tempErrors.IP_FCHA_CRCN_INI = {
				valid: false,
				msg: 'Se requiere un valor en el campo Fecha de creación inicial. Verifique',
			};
		} else if (
			tempErrors.IP_FCHA_CRCN_INI.msg.includes('Se requiere') &&
			IP_FCHA_CRCN_INI !== ''
		) {
			// Limpia el error si ya ingresaron fecha inicio
			tempErrors.IP_FCHA_CRCN_INI = { valid: true, msg: '' };
		}

		// -- Validación de "fecha fin" vacía si hay "fecha inicio"
		if (IP_FCHA_CRCN_INI !== '' && IP_FCHA_CRCN_FIN === '') {
			tempErrors.IP_FCHA_CRCN_FIN = {
				valid: false,
				msg: 'Se requiere un valor en el campo Fecha de creación final. Verifique',
			};
		} else if (
			tempErrors.IP_FCHA_CRCN_FIN.msg.includes('Se requiere') &&
			IP_FCHA_CRCN_FIN !== ''
		) {
			// Limpia el error si ya ingresaron fecha fin
			tempErrors.IP_FCHA_CRCN_FIN = { valid: true, msg: '' };
		}

		// -- Validación de rango de fechas
		if (IP_FCHA_CRCN_INI !== '' && IP_FCHA_CRCN_FIN !== '') {
			const startDate = new Date(IP_FCHA_CRCN_INI);
			const endDate = new Date(IP_FCHA_CRCN_FIN);

			// Si la fecha inicial es mayor que la fecha final -> error en ambos
			if (startDate > endDate) {
				tempErrors.IP_FCHA_CRCN_INI = {
					valid: false,
					msg: 'La fecha inicial no puede ser mayor que la fecha final. Verifique',
				};
				tempErrors.IP_FCHA_CRCN_FIN = {
					valid: false,
					msg: 'La fecha final no puede ser menor que la fecha inicial. Verifique',
				};
			} else {
				// Si las fechas están bien, limpiar errores
				// (solo si no había otro error previo, puedes ajustar la lógica según necesites)
				if (tempErrors.IP_FCHA_CRCN_INI.msg.includes('no puede ser mayor')) {
					tempErrors.IP_FCHA_CRCN_INI = { valid: true, msg: '' };
				}
				if (tempErrors.IP_FCHA_CRCN_FIN.msg.includes('no puede ser menor')) {
					tempErrors.IP_FCHA_CRCN_FIN = { valid: true, msg: '' };
				}
			}
		}

		setErrors(tempErrors);
	}, [formValues]);

	const handleClick = (values: any) => {
		const {
			IP_NRP,
			IP_NSS,
			IP_NMRO_CRDO,
			IP_TIPO_AVSO,
			IP_FCHA_CRCN_INI,
			IP_FCHA_CRCN_FIN,
			IP_LIST_CLMS,
		} = values;

		const allFieldsEmpty =
			!IP_NRP.trim() &&
			!IP_NSS.trim() &&
			!IP_NMRO_CRDO.trim() &&
			!IP_TIPO_AVSO.trim() &&
			!IP_FCHA_CRCN_INI.trim() &&
			!IP_FCHA_CRCN_FIN.trim() &&
			IP_LIST_CLMS === 'ALL';

		if (allFieldsEmpty) {
			setCriteriaWarningModal(true);
			return;
		}
		setServiceConfirmModal(true);
	};

	const handleCheckboxChange = (e: ChangeEvent<HTMLInputElement>) => {
		const { name, checked } = e.target;
		setFormValues({
			...formValues,
			[name]: checked ? '1' : null,
		});
	};

	const handleSelectMultipleChange = (selected: ISelectOption[]) => {
		const convertedSelectedOptions = selected.map((selectOption) => ({
			id: selectOption.value,
			name: selectOption.nombre,
		}));
		setSelectedOptions(convertedSelectedOptions);

		const idsString = selected.map((selectOption) => selectOption.value).join(',');
		setFormValues({
			...formValues,
			IP_LIST_CLMS: idsString,
		});
	};

	const handleRestForm = () => {
		setDisabledInput(false);
		setFormValues(initialValues);
		setErrors(generateInitialErrors(initialValues));
		setMetrics([]);
		setDataTable([]);
		setSelectedOptions([]);
	};

	useEffect(() => {
		const hasErrors = Object.values(errors).some((error) => !error.valid);
		setDisabled(hasErrors);
	}, [errors]);

	const consultFilter = () => {
		setServiceConfirmModal(false);
		setDisabledInput(true);
		getGeneralMetrics({
			...formValues,
			IP_CLSN_AVSO: currentTab === 3 ? '0' : currentTab.toString(),
		});
	};

	useEffect(() => {
		if (status === 'success') {
			getTableService({
				...formValues,
				IP_CLSN_AVSO: currentTab === 3 ? '0' : currentTab.toString(),
			});
		}
	}, [status]);

	return {
		formValues,
		handleInputChange,
		handleCheckboxChange,
		handleClick,
		errors,
		handleRestForm,
		disabled,
		consultFilter,
		handleSelectMultipleChange,
		selectedOptions,
	};
}
