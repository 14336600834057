import useModal from '../../../../../../../hooks/useModal';
import { useLoading } from '../../../../../../../hooks/useLoading';
import { useApiRequest } from '../../../../../../../hooks/useApiRequest';
import { useEffect } from 'react';
import { useFreeNoticeDownload } from '../../../../../context/useFreeNoticeDownloadContext';

function sanitizeForJSON(value: string): string {
	return value.replace(/[^a-zA-Z0-9_]/g, '').toUpperCase();
}

function sanitizeForXML(value: string): string {
	let sanitized = value.replace(/\s+/g, '-');
	sanitized = sanitized.replace(/[^a-zA-Z0-9-_]/g, '');
	if (/^\d/.test(sanitized)) {
		sanitized = 'x' + sanitized;
	}
	return sanitized;
}

export function useDownloadForm() {
	const { openErrorModal } = useModal();
	const { loadingFalseTwo, loadingTrueTwo } = useLoading();
	const { formValues, rfc, currentTab, headersMap, folioAvisoList } =
		useFreeNoticeDownload();
	const { value, status, execute } = useApiRequest<any, any>({
		path: 'servicio-gratuito/insumos-base64',
	});

	useEffect(() => {
		if (status === 'success') {
			if (value?.code === '0002') {
				handleFileDownload(
					value?.data.extension,
					value?.data.archivo,
					value?.data.nombreArchivo
				);
				loadingFalseTwo();
			} else {
				//modalErrorSlice(value?.data.message);
				loadingFalseTwo();
			}

			if (value?.code === '0003') {
				openErrorModal(value?.message);
			}
		} else if (status === 'error') {
			openErrorModal(value?.message);
			loadingFalseTwo();
		}
	}, [status]);

	const handleFileDownload = (
		extension: string,
		data: string,
		nombreArchivo: string
	) => {
		switch (extension) {
			case 'xls':
				downloadFile(data, `${nombreArchivo}.xls`, 'application/vnd.ms-excel');
				break;
			case 'xml':
				downloadFile(data, `${nombreArchivo}.xml`, 'application/xml');
				break;
			case 'json':
				downloadFile(data, `${nombreArchivo}.json`, 'application/json');
				break;
			case 'txt':
				downloadFile(data, `${nombreArchivo}.txt`, 'text/plain');
				break;
			default:
		}
	};

	const downloadFile = (base64Data: string, fileName: string, fileType: string) => {
		const link = document.createElement('a');
		link.href = `data:${fileType};base64,${base64Data}`;
		link.download = fileName;
		document.body.appendChild(link);
		link.click();
		document.body.removeChild(link);
	};

	const downloadDocument = (format: string, separador?: string) => {
		loadingTrueTwo();

		const type = currentTab === 3 ? '0' : currentTab.toString();

		const valuesString: string = headersMap
			.filter((item: any) => Number(item.value) !== 0) // filtramos los que tengan value distinto de 0
			.map((item: any) => item.value)
			.join(',');

		const newFormValues = {
			ordenCampos: valuesString,
			//IP_CLSN_AVSO
			ultimoAviso: formValues.IP_ULTO_AVSO,
			nrp: formValues.IP_NRP,
			nss: formValues.IP_NSS,
			numeroCredito: formValues.IP_NMRO_CRDO,
			tipoConsulta: type,
			fechaCreacionInicial: formValues.IP_FCHA_CRCN_INI,
			fechaCreacionFinal: formValues.IP_FCHA_CRCN_FIN,
			bitacora: '0',
			tipoAviso: formValues.IP_TIPO_AVSO,
		};

		execute({
			rfcEmpresa: rfc,
			formato: format,
			separador: separador,
			...newFormValues,
			folioAvisoList: folioAvisoList,
			tituloCampos: headersMap
				.filter((h: any) => Number(h.value) !== 0)
				.map((h: any) => {
					let tituloSanitizado = h.name;

					if (format === 'json') {
						tituloSanitizado = sanitizeForJSON(tituloSanitizado);
					} else if (format === 'xml') {
						tituloSanitizado = sanitizeForXML(tituloSanitizado);
					}

					return {
						id: Number(h.value),
						titulo: tituloSanitizado,
					};
				}),
		});
	};

	return {
		value,
		status,
		downloadDocument,
	};
}
