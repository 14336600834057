import { useMemo, useState, useEffect, useContext } from 'react';
import {
	isCURPValid,
	isNameValid,
	isPwdValid,
	isPhoneValid,
	isRFC,
} from '../../../../../../../utils/validators';
import useCustomScript from '../../../../../../../components/hooks/useCustomScript';
import {
	ICreateUserParams,
	ICreateUserResponse,
} from '../../../../../interfaces/create-user.interface';
import { URLS } from '../../../../../../../constants/urls.constants';
import {
	defaulEfirmaValidationResponse,
	IDatosEfirma,
	IResponseEfirmaValidation,
} from '../../../../../../../interfaces/efirma.interface';
import { useApiRequest } from '../../../../../../../hooks/useApiRequest';
import { INTERNAL_MESSAGES } from '../../../../../../../config/response-codes';
import { useEfirmaValidation } from '../../../../../../../hooks/validateEfirmaFunction';
import { CreateAccountContext } from '../../../../../context/CreateAccountContext';
import { useDispatch } from 'react-redux';
import { setLoadingRequest } from '../../../../../../../store/modules/loadingRequest';

declare global {
	interface Window {
		validarEFirma: any;
	}
}
interface IUseFiel {
	cancelOption: () => void;
	setActualStep: React.SetStateAction<any>;
	showPwd1: boolean;
	nrpHasEmission: boolean;
}

export function useFiel({
	cancelOption,
	setActualStep,
	showPwd1,
	nrpHasEmission,
}: IUseFiel) {
	useCustomScript('/efirma/efirma-validacion.ofu.min.js');
	useCustomScript('/js/jquery.min.js');
	useCustomScript('/js/jsrsasign-latest-all-min.js');
	const validarEFirma = window.validarEFirma;

	const dispatch = useDispatch();

	//eFirma data
	const [efirmaValidationResponseData, setEfirmaValidationResponseData] =
		useState<IResponseEfirmaValidation>(defaulEfirmaValidationResponse);
	const [eFirmaData, setEFirmaData] = useState<IDatosEfirma>({
		keyBinary: '',
		cerBinary: '',
		cerB64: '',
		psw: '',
	});

	const [errorPassEfirma, setErrorPassEfirma] = useState({
		valid: false,
		msg: '',
	});
	const [errorKeyFile, setErrorKeyFile] = useState({
		valid: false,
		msg: '',
	});
	const [errorCerFile, setErrorCerFile] = useState({
		valid: false,
		msg: '',
	});
	//eFirma data

	const { createAccountData } = useContext(CreateAccountContext);

	const [code, setCode] = useState('');
	const [codeInput, setCodeInput] = useState('');
	const [refreshCaptcha, setRefreshCaptcha] = useState(false);
	const [validCaptcha, setValidCaptcha] = useState(false);

	const [pwdConfirmation, setPwdConfirmation] = useState('');

	const [showModal, setShowModal] = useState(false);
	const [modalMessage, setModalMessage] = useState('');
	const [errorContrasenia, setErrorContrasenia] = useState({ valid: false, msg: '' });
	const [errorConfirmacionContrasenia, setErrorConfirmacionContrasenia] = useState({
		valid: false,
		msg: '',
	});
	const { validateEfirmaFunction } = useEfirmaValidation({
		setEfirmaValidationResponseData,
		setModalMessage,
		setShowModal,
		formHasCapcha: true,
		setRefreshCaptcha,
		refreshCaptcha,
		setErrorPassEfirma,
		setErrorKeyFile,
		setErrorCerFile,
	});

	const cancelFielValidation = () => {
		window.scroll(0, 0);
		if (!nrpHasEmission) {
			setActualStep(0);
		}
		cancelOption();
	};

	const disabledButton = useMemo((): boolean => {
		if (showPwd1 === true) {
			if (
				eFirmaData.psw !== '' &&
				eFirmaData.keyBinary !== '' &&
				eFirmaData.cerB64 !== '' &&
				eFirmaData.cerBinary !== '' &&
				isNameValid(createAccountData.nombre, 'nombre').valid &&
				isNameValid(createAccountData.apPaterno, 'primerApellido').valid &&
				isRFC(createAccountData.rfc) &&
				isCURPValid(createAccountData.curp).valid &&
				isPhoneValid(createAccountData.telefono).valid &&
				isPwdValid(createAccountData.psw!) &&
				errorContrasenia.valid &&
				errorConfirmacionContrasenia.valid &&
				createAccountData.psw === pwdConfirmation &&
				codeInput.length > 0
			) {
				if (createAccountData.apMaterno!.length > 0)
					return !isNameValid(createAccountData.apMaterno!, 'segundoApellido')
						.valid;
				else return false;
			} else return true;
		} else {
			if (
				eFirmaData.psw !== '' &&
				eFirmaData.keyBinary !== '' &&
				eFirmaData.cerB64 !== '' &&
				eFirmaData.cerBinary !== '' &&
				isNameValid(createAccountData.nombre, 'nombre').valid &&
				isNameValid(createAccountData.apPaterno, 'primerApellido').valid &&
				isRFC(createAccountData.rfc) &&
				isCURPValid(createAccountData.curp).valid &&
				isPhoneValid(createAccountData.telefono).valid &&
				codeInput.length > 0
			) {
				if (createAccountData.apMaterno!.length > 0)
					return !isNameValid(createAccountData.apMaterno!, 'segundoApellido')
						.valid;
				else return false;
			} else return true;
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		eFirmaData.cerB64,
		eFirmaData.cerBinary,
		eFirmaData.keyBinary,
		eFirmaData.psw,
		createAccountData,
		pwdConfirmation,
		codeInput,
		errorContrasenia,
		errorConfirmacionContrasenia,
	]);

	//Conexión del backend

	const {
		execute: createUser,
		value,
		status: statusCreateUser,
	} = useApiRequest<ICreateUserParams, ICreateUserResponse>({
		path: URLS.CREATE_ACCOUNT.REGISTER_EFIRMA,
	});

	useEffect(() => {
		if (statusCreateUser === 'success') {
			dispatch(setLoadingRequest({ loadingRequest: false }));
			if (value?.code === '0000') {
				setActualStep(2);
			} else {
				setModalMessage(value?.message!);
				setShowModal(true);
				setRefreshCaptcha(!refreshCaptcha);
			}
		} else if (statusCreateUser === 'error') {
			dispatch(setLoadingRequest({ loadingRequest: false }));

			setModalMessage(INTERNAL_MESSAGES.ERROR_MESSAGE);
			setShowModal(true);
			setRefreshCaptcha(!refreshCaptcha);
		}
	}, [value?.code, statusCreateUser]); // eslint-disable-line react-hooks/exhaustive-deps

	//Fin conexión

	useEffect(() => {
		if (efirmaValidationResponseData.valido) {
			dispatch(setLoadingRequest({ loadingRequest: true }));

			createUser({
				...createAccountData,
				rfcEfirma: efirmaValidationResponseData.rfc,
			});
		}
	}, [efirmaValidationResponseData]); // eslint-disable-line react-hooks/exhaustive-deps

	const validateFiel = () => {
		dispatch(setLoadingRequest({ loadingRequest: true }));

		setTimeout(() => {
			if (typeof validarEFirma === 'function') {
				validarEFirma(
					{
						...eFirmaData,
						password: eFirmaData.psw,
						serverIP: URLS.EFIRMA.VALIDATE,
					},
					validateEfirmaFunction
				);
			} else {
				setModalMessage(INTERNAL_MESSAGES.ERROR_MESSAGE);
				setShowModal(true);
			}
		}, 2000);
	};

	const handleValidate = () => {
		if (!code || codeInput !== code) {
			setRefreshCaptcha(!refreshCaptcha);
			return setValidCaptcha(true);
		}
		validateFiel();
	};

	return {
		eFirmaData,
		errorPassEfirma,
		errorKeyFile,
		errorCerFile,
		code,
		disabledButton,
		pwdConfirmation,
		showModal,
		modalMessage,
		codeInput,
		refreshCaptcha,
		validCaptcha,
		setPwdConfirmation,
		setEFirmaData,
		setShowModal,
		cancelFielValidation,
		validateFiel,
		setCodeInput,
		setCode,
		setValidCaptcha,
		handleValidate,
		errorConfirmacionContrasenia,
		setErrorConfirmacionContrasenia,
		errorContrasenia,
		setErrorContrasenia,
	};
}
