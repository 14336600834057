interface Option {
	value: number;
	id: string;
	name: string;
}

export const tableHeader: Option[] = [
	{ value: 1, id: 'ID_NMRO_AVSO', name: 'Folio aviso' },
	//{ value: 2, id: 'CV_RFC_EMPA', name: 'RFC Empresa' },
	{ value: 3, id: 'CV_NRP', name: 'NRP' },
	{ value: 4, id: 'TX_RZON_SCAL', name: 'Razón social' },
	{ value: 5, id: 'TX_DMCO_EMPA', name: 'Calle / domicilio' },
	{ value: 6, id: 'CV_NSS', name: 'NSS' },
	{ value: 7, id: 'TX_NMBE_TRBR', name: 'Nombre del trabajador' },
	{ value: 8, id: 'CV_RFC_TRBR', name: 'RFC trabajador' },
	{ value: 9, id: 'CV_CURP_TRBR', name: 'CURP trabajador' },
	{ value: 10, id: 'CV_NMRO_CRDO', name: 'Número de crédito' },
	{ value: 11, id: 'FH_FCHA_CRCN_AVSO', name: 'Fecha aviso' },
	{ value: 12, id: 'FH_FCHA_OTRO_CRDO', name: 'Fecha de otorgamiento del crédito' },
	{ value: 13, id: 'CV_TIPO_AVSO', name: 'Clave tipo de aviso' },
	{ value: 14, id: 'CV_TIPO_DSCO', name: 'Clave tipo de descuento' },
	{ value: 15, id: 'IM_FCTR_DSCO_ACTL', name: 'Factor de descuento' },
	{ value: 16, id: 'IM_MNTO_DSCO', name: 'Monto de descuento' },
	{ value: 18, id: 'CV_ESTS_CRDO', name: 'Clave estatus crédito' },
	//{ value: 19, id: 'CV_TIPO_CRDO', name: 'Tipo de crédito' },
	//{ value: 20, id: 'CV_NSS_UNFO', name: 'NSS Unificado' },
	{ value: 21, id: 'FH_FCHA_ULTO_AVSO', name: 'Fecha creación del aviso' },
	{ value: 24, id: 'FH_FCHA_NTFN_LNEA', name: 'Fecha de notificación en línea' },
	//{ value: 25, id: 'TX_CDNA_ORGL', name: 'Cadena Original' },
	//{ value: 26, id: 'TX_SLLO_DGTL', name: 'Sello Digital' },
	{ value: 0, id: 'actions', name: '' },
];
