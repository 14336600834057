import useModal from '../../../hooks/useModal';
import { useApiRequest } from '../../../hooks/useApiRequest';
import { useFreeNoticeDownload } from '../context/useFreeNoticeDownloadContext';
import { useLoading } from '../../../hooks/useLoading';
import { useTableAdapter } from '../adapters/table.adapters';
import { useEffect } from 'react';

interface ILoginBalPiParams {
	IP_RFC: string;
	IP_LIST_CLMS: string;
	IP_CLSN_AVSO: string;
	IP_ULTO_AVSO: string | null;
	IP_NRP?: string;
	IP_NSS?: string;
	IP_NMRO_CRDO?: string;
	IP_TIPO_AVSO?: string;
	IP_FCHA_CRCN_INI?: string;
	IP_FCHA_CRCN_FIN?: string;
}

export interface IGetTableParams {
	IP_CLSN_AVSO: string;
	IP_NRP?: string;
	IP_NMRO_CRDO?: string;
	IP_TIPO_AVSO?: string;
	IP_ULTO_AVSO?: string;
	IP_LIST_CLMS?: string;
	IP_NSS?: string;
	IP_FCHA_CRCN_INI?: string;
	IP_FCHA_CRCN_FIN?: string;
}

export function useTableService() {
	const { openErrorModal } = useModal();
	const { loadingFalseTwo } = useLoading();
	const { setDataTable, setIsLoadingTable, rfc } = useFreeNoticeDownload();
	const { responseTableAdapter } = useTableAdapter();
	const { execute, value, status } = useApiRequest<ILoginBalPiParams, any>({
		path: 'servicio-gratuito/metodo-general',
	});

	useEffect(() => {
		if (status === 'success') {
			if (value?.code === '0000') {
				setDataTable([]);
			}
			if (value?.code === '0001') {
				setDataTable(responseTableAdapter(value?.data));
			}
			setIsLoadingTable(false);
			loadingFalseTwo();
		} else if (status === 'error') {
			openErrorModal(value?.message);
			setIsLoadingTable(false);
			loadingFalseTwo();
		}
	}, [status]);

	//ultimoAviso
	const getTableService = (data: IGetTableParams) => {
		setIsLoadingTable(true);
		execute({
			...data,
			IP_LIST_CLMS: data.IP_LIST_CLMS || 'ALL',
			IP_ULTO_AVSO: data.IP_ULTO_AVSO || null,
			IP_RFC: rfc || '',
		});
	};
	return {
		value,
		status,
		getTableService,
	};
}
