import useModal from '../../../hooks/useModal';
import { useLoading } from '../../../hooks/useLoading';
import { useApiRequest } from '../../../hooks/useApiRequest';
import { useEffect, useState } from 'react';
import { useFreeNoticeDownload } from '../context/useFreeNoticeDownloadContext';
import { adaptFormValues } from '../adapters/download-masive.adapter';
import { TABS } from '../constants/tabs.constants';

export function useMassiveNoticeDownload() {
	const { openErrorModal } = useModal();
	const { loadingTrueTwo, loadingFalseTwo } = useLoading();
	const { formValues, rfc, folioAvisoList, currentTab } = useFreeNoticeDownload();

	const { execute, value, status } = useApiRequest<any, any>({
		path: 'servicio-gratuito/descargar-aviso-masiva',
	});

	const {
		execute: executeGenerate,
		value: valueGenerate,
		status: statusGenerate,
	} = useApiRequest<any, any>({
		path: 'servicio-gratuito/descargar-aviso-masiva',
	});

	const [currentPage, setCurrentPage] = useState(1);
	const [totalPages, setTotalPages] = useState<number | null>(null);

	useEffect(() => {
		if (statusGenerate === 'success') {
			if (
				valueGenerate?.code === '0200' &&
				valueGenerate?.internalCode === '0000'
			) {
				setTimeout(() => {
					executeDownloadMassiveZip(1);
				}, 10000);
			} else if (valueGenerate?.code === '0503') {
				openErrorModal(valueGenerate?.message);
				loadingFalseTwo();
			}
		} else if (status === 'error') {
			openErrorModal(valueGenerate?.message);
			loadingFalseTwo();
		}
	}, [statusGenerate]);

	useEffect(() => {
		if (status === 'success') {
			if (value?.code === '0200' && value?.internalCode === '0000') {
				if (value?.data.estatus === '1') {
					if (totalPages === null) {
						setTimeout(() => {
							executeDownloadMassiveZip(currentPage);
						}, 2000);
					} else {
						executeDownloadMassiveZip(currentPage);
					}
				}

				if (value?.data.estatus === '2') {
					downloadFile(
						value?.data?.archivo,
						value?.data?.nombreArchivo,
						'application/zip'
					);

					if (totalPages === null) {
						const paginas = value?.data?.paginas || 0;
						setTotalPages(paginas);

						if (currentPage < paginas) {
							setCurrentPage((prevPage) => prevPage + 1);
							executeDownloadMassiveZip(currentPage + 1);
						} else {
							loadingFalseTwo();
						}
					} else {
						if (currentPage < totalPages) {
							setCurrentPage((prevPage) => prevPage + 1);
							executeDownloadMassiveZip(currentPage + 1);
						} else {
							loadingFalseTwo();
						}
					}
				}
				if (value?.data.estatus === '3') {
					openErrorModal(value?.data.mensaje);
					loadingFalseTwo();
				}
			} else if (value?.code === '0503') {
				openErrorModal(value?.message);
				loadingFalseTwo();
			}
		} else if (status === 'error') {
			openErrorModal(value?.message);
			loadingFalseTwo();
		}
	}, [status]);

	const downloadFile = (base64Data: string, fileName: string, fileType: string) => {
		const link = document.createElement('a');
		link.href = `data:${fileType};base64,${base64Data}`;
		link.download = fileName;
		document.body.appendChild(link);
		link.click();
		document.body.removeChild(link);
	};

	const executeDownloadMassiveZip = (page: number) => {
		loadingTrueTwo();
		const params = adaptFormValues(
			{
				...formValues,
				rfcEmpresa: rfc || '',
				folioAvisoList: folioAvisoList,
				tipoConsulta: noticeType(currentTab),
			},
			page
		);
		execute(params);
	};

	const executeMassiveNotice = () => {
		loadingTrueTwo();
		const params = adaptFormValues(
			{
				...formValues,
				rfcEmpresa: rfc || '',
				folioAvisoList: folioAvisoList,
				tipoConsulta: noticeType(currentTab),
			},
			0
		);
		executeGenerate(params);
	};

	const noticeType = (currentTab: number): string => {
		switch (currentTab) {
			case TABS.NOTICE:
				return '0';
			case TABS.ACCREDITED:
				return '1';
			case TABS.SUSPENDED:
				return '2';
			default:
				return '0';
		}
	};

	return {
		value,
		status,
		executeMassiveNotice,
	};
}
