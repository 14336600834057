import { useDispatch } from 'react-redux';
import { setLoading } from '../store/modules/loadingTwo';
import { setLoading as setLoadingTwo } from '../store/modules/loading';
import { setLoading as setLoadingThree } from '../store/modules/loadingThree';

export function useLoading() {
	const dispatch = useDispatch();

	function loadingFalse() {
		dispatch(setLoading({ loading: false }));
	}

	function loadingTrue() {
		dispatch(setLoading({ loading: true }));
	}

	function loadingFalseTwo() {
		dispatch(setLoadingTwo({ loading: false }));
	}

	function loadingTrueTwo() {
		dispatch(setLoadingTwo({ loading: true }));
	}

	function loadingFalseThree() {
		dispatch(setLoadingThree({ loading: false }));
	}

	function loadingTrueThree(message: string) {
		dispatch(setLoadingThree({ loading: true, message: message }));
	}

	return {
		loadingFalse,
		loadingTrue,
		loadingFalseTwo,
		loadingTrueTwo,
		loadingFalseThree,
		loadingTrueThree,
	};
}
