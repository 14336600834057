import React, { Fragment } from 'react';
import CustomButton from '../../../../../../components/button.component';
import { useFiel } from './hooks/useFiel';
import ModuloEfirma from '../../../../../../components/efirma.component';
import Captcha from '../../../../../../components/captcha.component';
import CustomModal from '../../../../../../components/modal.component';
import CustomAlertCC from '../../../custom-alert/custom-alert-cc.component';
import GeneralDataForm from '../general-data-form';

import styles from './styles/fiel-form.styles.component.module.css';

interface IFielFrom {
	cancelOption: () => void;
	setActualStep: React.SetStateAction<any>;
	showPwd1: boolean;
	nrpHasEmission: boolean;
}

const FielForm = (props: IFielFrom) => {
	const {
		eFirmaData,
		errorPassEfirma,
		errorKeyFile,
		errorCerFile,
		disabledButton,
		showModal,
		modalMessage,
		codeInput,
		refreshCaptcha,
		validCaptcha,
		pwdConfirmation,
		setPwdConfirmation,
		setEFirmaData,
		setValidCaptcha,
		setCodeInput,
		setCode,
		setShowModal,
		cancelFielValidation,
		handleValidate,
		errorConfirmacionContrasenia,
		setErrorConfirmacionContrasenia,
		errorContrasenia,
		setErrorContrasenia,
	} = useFiel(props);

	return (
		<div className={styles.fielFormContainer}>
			<CustomModal
				openModal={showModal}
				labelButton="Cerrar"
				closeButton={true}
				setOpenModal={setShowModal}
				type="error"
			>
				<Fragment>{modalMessage}</Fragment>
			</CustomModal>
			<CustomAlertCC message="Validación del registro con e.firma (empresa)" />
			<ModuloEfirma
				efirmaData={eFirmaData}
				setEfirmaData={setEFirmaData}
				errorPassEfirma={errorPassEfirma}
				errorKeyFile={errorKeyFile}
				errorCerFile={errorCerFile}
			/>
			<hr className={styles.hrFiel} />
			<GeneralDataForm
				pwdConfirmation={pwdConfirmation}
				setPwdConfirmation={setPwdConfirmation}
				errorContrasenia={errorContrasenia}
				setErrorContrasenia={setErrorContrasenia}
				errorConfirmacionContrasenia={errorConfirmacionContrasenia}
				setErrorConfirmacionContrasenia={setErrorConfirmacionContrasenia}
			/>
			<p className={styles.datosObligatoriosTxt}>
				<span className="mandatory">*</span>Datos obligatorios
			</p>
			<div className={styles.containerCaptcha}>
				<Captcha
					value={codeInput}
					getNew={refreshCaptcha}
					onChange={(newValue: string, codeCaptcha: string) => {
						setCodeInput(newValue);
						setCode(codeCaptcha);
					}}
					error={validCaptcha}
					setError={setValidCaptcha}
				/>
			</div>
			<div className={styles.containerButtonsFormFiel}>
				<CustomButton
					onClick={cancelFielValidation}
					variant="outlined"
					label="Regresar"
				/>
				<CustomButton
					onClick={handleValidate}
					variant="solid"
					label="Crear cuenta"
					disabled={disabledButton}
				/>
			</div>
		</div>
	);
};

export default FielForm;
