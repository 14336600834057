import { Visibility, VisibilityOff } from '@mui/icons-material';
import style from './styles/show-and-hide-pwd-styles.component.module.css';

interface ShowPwdProps {
	show: boolean;
	onClick: () => void;
}

export function ShowAndHidePwd({ show, onClick }: ShowPwdProps) {
	return (
		<button className={style.button} onClick={onClick} tabIndex={-1} type="button">
			{show ? <Visibility /> : <VisibilityOff />}
		</button>
	);
}
