import { TableCustom } from '../../../../components';
import { tableHeader } from '../../constants/table.constants';
import { FilterForm } from '../../components/filter-form/header.component';
import { TotalTable } from '../../components/total-table/total-table.component';
import { ExportButtons } from '../../components/export-buttons/export-buttons.component';
import { FooterFreeNoticeDownload } from '../../components/footer/footer.component';
import { useFreeNoticeDownload } from '../../context/useFreeNoticeDownloadContext';
import { useCallback } from 'react';

import styles from './notice-inquiry.module.css';

export function NoticeInquiry() {
	const { dataTable, isLoadingTable, setFolioAvisoList } = useFreeNoticeDownload();

	const onSelectedRowsChange = useCallback((data: any) => {
		const transformedData = data.map((item: any) => ({
			folioAviso: item.ID_NMRO_AVSO,
		}));
		setFolioAvisoList(transformedData);
	}, []);

	return (
		<div className={styles.content}>
			<FilterForm />
			<TotalTable />
			<TableCustom
				headers={tableHeader}
				data={dataTable}
				rowsPerPage={5}
				isLoading={isLoadingTable}
				idKey="ID_NMRO_AVSO"
				selectable
				onSelectedRowsChange={onSelectedRowsChange}
				align={'center'}
			/>
			<ExportButtons />
			<FooterFreeNoticeDownload />
		</div>
	);
}
