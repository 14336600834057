import useModal from '../../../hooks/useModal';
import { useLoading } from '../../../hooks/useLoading';
import { useApiRequest } from '../../../hooks/useApiRequest';
import { useEffect } from 'react';
import { downloadIndividualAdapter } from '../adapters/download-individual.adapter';
import { useFreeNoticeDownload } from '../context/useFreeNoticeDownloadContext';

export function useDownloadIndividualPDF() {
	const { openErrorModal } = useModal();
	const { loadingTrueTwo, loadingFalseTwo } = useLoading();
	const { execute, value, status } = useApiRequest<any, any>({
		path: 'servicio-gratuito/descargar-aviso-individual',
	});

	const { rfc } = useFreeNoticeDownload();

	useEffect(() => {
		if (status === 'success') {
			if (value?.code === '0200') {
				downloadFile(
					value?.data?.archivo,
					value?.data?.nombreArchivo,
					'application/pdf'
				);
			}
			if (value?.code === '0503') {
				openErrorModal(value?.message);
			}
			loadingFalseTwo();
		} else if (status === 'error') {
			openErrorModal(value?.message);
			loadingFalseTwo();
		}
	}, [status]);

	const downloadFile = (base64Data: string, fileName: string, fileType: string) => {
		const link = document.createElement('a');
		link.href = `data:${fileType};base64,${base64Data}`;
		link.download = fileName;
		document.body.appendChild(link);
		link.click();
		document.body.removeChild(link);
	};

	const downloadIndividualPdf = (data: any) => {
		loadingTrueTwo();
		execute(downloadIndividualAdapter(data, rfc!));
	};

	return {
		value,
		status,
		downloadIndividualPdf,
	};
}
