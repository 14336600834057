import { createSlice } from '@reduxjs/toolkit';

export const loadingThreeSlice = createSlice({
	name: 'loadingThree',
	initialState: {
		loading: false,
		message: '',
	},
	reducers: {
		setLoading: (state, action) => ({
			...state,
			...action.payload,
		}),
		stopLoading: (state) => ({
			...state,
			loading: false,
		}),
	},
});

export const { setLoading, stopLoading } = loadingThreeSlice.actions;
export default loadingThreeSlice.reducer;
