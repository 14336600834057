import { ChangeEvent, Fragment } from 'react';
import { Input } from '../../../../components';
import SelectTwo from '../select/SelectTwo';
import SelectMultiple from '../../../../components/select-multiple/select-multiple.component';
import Datepicker from '../../../../components/datepicker.component';
import CustomButton from '../../../../components/button.component';
import { Checkbox, FormControlLabel } from '@mui/material';
import { Filter } from '../filter';
import { useNoticeInquiryForm } from '../../pages/notice-inquiry/hooks/useNoticeInquiryForm';
import { useFreeNoticeDownload } from '../../context/useFreeNoticeDownloadContext';
import { CriteriaWarningModal } from '../criteria-warning-modal/criteria-warning-modal.component';
import { ServiceConfirmModal } from '../service-confirm-modal/service-confirm-modal.component';
import { TABS } from '../../constants/tabs.constants';

import styles from './filter-form.module.css';
import { useSupplyColumns } from '../../hooks/useSupplyColumns';
import dayjs from 'dayjs';

export function FilterForm() {
	const { catalogTypeServiceOptions, currentTab, disableInputs } =
		useFreeNoticeDownload();
	const {
		formValues,
		handleInputChange,
		handleClick,
		handleCheckboxChange,
		errors,
		handleRestForm,
		disabled,
		consultFilter,
		handleSelectMultipleChange,
		selectedOptions,
	} = useNoticeInquiryForm();

	const { columnsOptions } = useSupplyColumns();

	return (
		<Fragment>
			<Filter onClick={handleRestForm}>
				<div className={styles.filter}>
					<div className={styles.container}>
						{currentTab === TABS.INPUT_GENERATOR && (
							<div className={styles.colTwo}>
								<SelectMultiple
									label="Campos disponibles"
									options={columnsOptions}
									name="select-multiple-options"
									afterSelect={handleSelectMultipleChange}
									selectedTexts={selectedOptions.map(
										(option) => option.id
									)}
									disabled={disableInputs}
								/>
							</div>
						)}
						<div className={styles.colTwo}>
							<Input
								id="nrp"
								label="NRP"
								name="IP_NRP"
								value={formValues.IP_NRP}
								onChange={handleInputChange}
								error={errors.IP_NRP}
								maxLength={11}
								disabled={disableInputs}
							/>
						</div>
						<div className={styles.colTwo}>
							<Input
								id="nss"
								label="NSS"
								name="IP_NSS"
								value={formValues.IP_NSS}
								onChange={handleInputChange}
								error={errors.IP_NSS}
								maxLength={11}
								disabled={disableInputs}
							/>
						</div>
						<div className={styles.colTwo}>
							<Input
								id="numeroCredito"
								label="Número de crédito"
								name="IP_NMRO_CRDO"
								value={formValues.IP_NMRO_CRDO}
								onChange={handleInputChange}
								error={errors.IP_NMRO_CRDO}
								maxLength={10}
								disabled={disableInputs}
							/>
						</div>
						<div className={styles.colTwo}>
							<SelectTwo
								label="Motivo del aviso"
								name="IP_TIPO_AVSO"
								options={catalogTypeServiceOptions}
								afterSelect={(value) => {
									const event = {
										target: {
											value: value.value,
											name: 'IP_TIPO_AVSO',
										},
									} as ChangeEvent<HTMLInputElement>;

									handleInputChange(event);
								}}
								disabled={disableInputs}
								selectedValue={formValues.IP_TIPO_AVSO}
							/>
						</div>
						{(currentTab === TABS.NOTICE ||
							currentTab === TABS.INPUT_GENERATOR) && (
							<Fragment>
								<div
									className={`${
										currentTab === TABS.INPUT_GENERATOR
											? styles.colTwo
											: styles.col
									}`}
								>
									<Datepicker
										label="Fecha aviso inicio"
										name="IP_FCHA_CRCN_INI"
										value={formValues.IP_FCHA_CRCN_INI}
										onChangeDate={handleInputChange}
										error={errors.IP_FCHA_CRCN_INI}
										disabled={disableInputs}
										maxDate={dayjs()}
									/>
								</div>
								<div
									className={`${
										currentTab === TABS.INPUT_GENERATOR
											? styles.colTwo
											: styles.col
									}`}
								>
									<Datepicker
										label="Fecha aviso fin"
										name="IP_FCHA_CRCN_FIN"
										value={formValues.IP_FCHA_CRCN_FIN}
										onChangeDate={handleInputChange}
										error={errors.IP_FCHA_CRCN_FIN}
										disabled={disableInputs}
										maxDate={dayjs()}
									/>
								</div>
								<div className={styles.buttons}>
									<FormControlLabel
										control={
											<Checkbox
												checked={formValues.IP_ULTO_AVSO === '1'}
												onChange={handleCheckboxChange}
												name="IP_ULTO_AVSO"
												color="primary"
												disabled={disableInputs}
											/>
										}
										label="Último aviso"
									/>
								</div>
							</Fragment>
						)}
					</div>
				</div>
			</Filter>
			<CustomButton
				variant="solid"
				styles={{
					maxWidth: '211px',
				}}
				onClick={() => handleClick(formValues)}
				disabled={disabled}
			>
				Consultar
			</CustomButton>
			<CriteriaWarningModal onContinue={consultFilter} />
			<ServiceConfirmModal onContinue={consultFilter} />
		</Fragment>
	);
}
