import { useNavigate } from 'react-router-dom';
import { Captcha, TextInput } from '../../../../components';
import CustomButton from '../../../../components/button.component';
import CustomModal from '../../../../components/modal.component';
import CustomAlertCC from '../custom-alert/custom-alert-cc.component';
import { useStep1 } from './hooks/useStep1';
import { Fragment, useContext } from 'react';
import { CreateAccountContext } from '../../context/CreateAccountContext';

import styles from './styles/style.component.module.css';

interface IStep1 {
	setActualStep: React.SetStateAction<any>;
	setShowPwd1: React.SetStateAction<any>;
	setNrpHasEmission: React.Dispatch<React.SetStateAction<boolean>>;
	setNrpEmissionMessage: React.Dispatch<React.SetStateAction<string>>;
}

const Step1 = (props: IStep1) => {
	const { createAccountData } = useContext(CreateAccountContext);
	const {
		setCode,
		setCodeInput,
		handleSetData,
		handleValidate,
		setValidCaptcha,
		setShowModal,
		confirmacionCorreo,
		errorNRP,
		errorCorreo,
		errorConfirmacionCorreo,
		codeInput,
		refreshCaptcha,
		validCaptcha,
		showModal,
		disabledButton,
	} = useStep1(props);
	const navigate = useNavigate();
	return (
		<Fragment>
			<CustomModal
				openModal={showModal}
				labelButton="Cerrar"
				closeButton={true}
				setOpenModal={setShowModal}
				type="error"
			>
				Por el momento los servicios no se encuentran disponibles. Intenta más
				tarde.
			</CustomModal>
			<CustomAlertCC message="Información de la empresa" />
			<span className={styles.textInformative}>
				<b>NRP</b> [Número de Registro Patronal] / <b>RPU</b> [Registro Patrinal
				Único]
			</span>
			<TextInput
				id="nrp"
				name="nrp"
				placeholder="NRP o RPU"
				labelStyles={{ fontSize: 16 }}
				contentStyles={{ marginBottom: 40 }}
				value={createAccountData.nrp}
				onChange={handleSetData}
				onBlur={handleSetData}
				labelComponent={
					<p className={styles.labelContainerInput}>
						NRP / RPU<span className="mandatory">*</span>
					</p>
				}
				error={!errorNRP.valid}
				helperText={!errorNRP.valid ? errorNRP.msg : ''}
			/>
			<CustomAlertCC message="Información de contacto" />
			<span className={styles.textInformative}>
				Escribe el correo del representante legal de la empresa
			</span>
			<TextInput
				id="correo"
				name="correo"
				placeholder="correo@electronico.com"
				labelStyles={{ fontSize: 16 }}
				contentStyles={{ marginBottom: 24 }}
				value={createAccountData.correo}
				onChange={handleSetData}
				onBlur={handleSetData}
				labelComponent={
					<p className={styles.labelContainerInput}>
						Correo electrónico<span className="mandatory">*</span>
					</p>
				}
				error={!errorCorreo.valid}
				helperText={!errorCorreo.valid ? errorCorreo.msg : ''}
				maxLength={60}
			/>
			<TextInput
				id="confirmacionCorreo"
				name="confirmacionCorreo"
				placeholder="correo@electronico.com"
				labelStyles={{ fontSize: 16 }}
				contentStyles={{ marginBottom: '40px' }}
				value={confirmacionCorreo}
				onChange={handleSetData}
				onBlur={handleSetData}
				labelComponent={
					<p className={styles.labelContainerInput}>
						Confirma correo electrónico<span className="mandatory">*</span>
					</p>
				}
				error={!errorConfirmacionCorreo.valid}
				helperText={
					!errorConfirmacionCorreo.valid ? errorConfirmacionCorreo.msg : ''
				}
				noPaste={true}
				maxLength={60}
			/>
			<p className={styles.datosObligatoriosTxt}>
				<span className="mandatory">*</span>Datos obligatorios
			</p>
			<div className={styles.containerCaptcha}>
				<Captcha
					value={codeInput}
					getNew={refreshCaptcha}
					onChange={(newValue: string, codeCaptcha: string) => {
						setCodeInput(newValue);
						setCode(codeCaptcha);
					}}
					error={validCaptcha}
					setError={setValidCaptcha}
				/>
			</div>
			<div className={styles.containerButtonsForm}>
				<CustomButton
					onClick={() => navigate('/')}
					variant="outlined"
					label="Regresar"
				/>
				<CustomButton
					onClick={handleValidate}
					variant="solid"
					label="Continuar"
					disabled={disabledButton}
				/>
			</div>
		</Fragment>
	);
};

export default Step1;
