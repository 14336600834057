import React from 'react';
import { useSelectMultiple } from './useSelectMultiple';
import { Label } from '../label.component';
import { AlertInput } from '../alert-input.component';
import style from './select-multiple.module.css';
import arrowDown from '../../assets/svg/arrow-down-s-fill.svg';
import arrowUp from '../../assets/svg/arrow-up-s-fill.svg';

import { ISelectMultiple, ISelectOption } from './select-multiple.interface';

export default function SelectMultiple({
	label,
	required,
	options,
	name,
	afterSelect,
	error,
	selectedTexts = [],
	disabled,
}: ISelectMultiple) {
	// Usamos nuestro hook
	const { ref, values, buttonValues, active, selectClick, selected } =
		useSelectMultiple({
			afterSelect,
			selectedTexts, // si deseas inicializar con algo
			options,
		});

	// Si hay más de 2 seleccionados, mostramos "N seleccionados"
	const displayText =
		buttonValues.length > 2
			? `${buttonValues.length} seleccionados`
			: buttonValues.join(', ');

	return (
		<div className={style.content} ref={ref}>
			<Label label={label} name={name} required={required} />
			<div className={style.contentSelect}>
				<button
					className={`${style.select} ${
						values.length > 0 ? style.notEmpty : style.empty
					}`}
					onClick={selectClick}
					type="button"
					disabled={disabled}
				>
					{displayText || 'Selecciona...'}
					<img
						src={active ? arrowUp : arrowDown}
						alt="icono"
						className={style.icon}
					/>
				</button>

				{active && !disabled && (
					<div className={style.options}>
						{options.map((option) => (
							<button
								className={`${style.option} ${
									values.some((v) => v.value === option.value)
										? style.selected
										: ''
								}`}
								key={option.value}
								onClick={() => selected(option)}
								type="button"
							>
								{option.nombre}
							</button>
						))}
					</div>
				)}

				{error && (
					<AlertInput name={name} valid={error.valid} message={error.msg} />
				)}

				{/* Input oculto para formularios */}
				<input
					name={name}
					value={values.map((v: ISelectOption) => v.value).join(',')}
					style={{ display: 'none' }}
					readOnly
				/>
			</div>
		</div>
	);
}
