import useModal from '../../../hooks/useModal';
import { useLoading } from '../../../hooks/useLoading';
import { useApiRequest } from '../../../hooks/useApiRequest';
import { useEffect } from 'react';
import { useFreeNoticeDownload } from '../context/useFreeNoticeDownloadContext';
import { catalogTypeServiceAdapters } from '../adapters/catalog-type-service.adapters';

interface ILoginBalPiParams {
	IP_CLSN_AVSO: string;
	IP_TIPO_AVSO: string;
}

export interface ICatalogTypeServiceData {
	TpoAvso: string;
	TxAvso: string;
}

export interface IGetCatalogTypeService {
	typeNotice: '01' | '10';
	ipNotice: '0' | '1' | '2';
}

export function useCatalogTypeService() {
	const { openErrorModal } = useModal();
	const { loadingFalseTwo, loadingTrueTwo } = useLoading();
	const { setCatalogTypeServiceOptions } = useFreeNoticeDownload();
	const { execute, value, status } = useApiRequest<
		ILoginBalPiParams,
		ICatalogTypeServiceData[]
	>({
		path: 'servicio-gratuito/catalogo-tipo-aviso',
	});

	useEffect(() => {
		if (status === 'success') {
			if (value?.code === '0001') {
				setCatalogTypeServiceOptions(catalogTypeServiceAdapters(value.data!));
				loadingFalseTwo();
			}
		} else if (status === 'error') {
			openErrorModal(value?.message);
			loadingFalseTwo();
		}
	}, [status]);

	const getCatalogTypeService = (data: IGetCatalogTypeService) => {
		loadingTrueTwo();
		setCatalogTypeServiceOptions([
			{
				nombre: '',
				value: '',
			},
		]);
		execute({
			IP_CLSN_AVSO: data.ipNotice,
			IP_TIPO_AVSO: data.typeNotice,
		});
	};

	return {
		value,
		status,
		getCatalogTypeService,
	};
}
