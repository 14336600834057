import { ICatalogTypeServiceData } from '../hooks/useCatalogTypeNotice';

interface IColumns {
	IdClma: string;
	DscnClma: string;
}

export function catalogTypeServiceAdapters(data: ICatalogTypeServiceData[]) {
	return data.map((item) => ({
		nombre: item.TxAvso,
		value: item.TpoAvso,
	}));
}

export function columnsServiceAdapters(data: IColumns[]) {
	return data.map((item) => ({
		nombre: item.DscnClma,
		value: item.IdClma,
	}));
}
