import { useState, ReactNode, Fragment } from 'react';
import Skeleton from '@mui/material/Skeleton';
import iconFilter from '../../../../assets/svg/filtro.svg';
import closeFilter from '../../../../assets/svg/close-fill.svg';
import styles from './styles.module.css';

interface FilterProps {
	children: ReactNode;
	onClick?: () => void;
	isLoading?: boolean;
}

export function Filter({ children, onClick, isLoading = false }: FilterProps) {
	const [showFilters, setShowFilters] = useState(true);

	const hiddenFilters = () => {
		//setShowFilters(false);
		if (!isLoading && onClick) onClick();
	};

	return (
		<div className={styles.content}>
			<div className={`${styles.filterTitle} ${showFilters && styles.titleActive}`}>
				<img src={iconFilter} alt="Filtros de Búsqueda" />
				<div onClick={() => setShowFilters(!showFilters)}>
					Filtros de Búsqueda
				</div>

				{showFilters && (
					<div className={styles.closeFilter} onClick={hiddenFilters}>
						<img src={closeFilter} alt="Filtros de Búsqueda" />
						Quitar filtros
					</div>
				)}
			</div>
			<div
				className={`${styles.containerFilter} ${
					showFilters ? styles.show : styles.hidden
				}`}
			>
				<div className={styles.filterContent}>
					{isLoading ? (
						<Fragment>
							<Skeleton variant="rectangular" width="100%" height={60} />
							<Skeleton variant="rectangular" width="100%" height={60} />
							<Skeleton variant="rectangular" width="100%" height={60} />
						</Fragment>
					) : (
						children
					)}
				</div>
			</div>
		</div>
	);
}
