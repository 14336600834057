import { useFreeNoticeDownload } from '../../context/useFreeNoticeDownloadContext';
import { MetricData } from '../../context/free-notice-download.interface';
import { labelMetrics } from '../../constants/metrics.constants';

import styles from './total-table.module.css';

export function TotalTable() {
	const { metrics, isLoadingTable } = useFreeNoticeDownload();
	const data = metrics[0] || {};

	const formatNumber = (val: string | number | null) => {
		if (val === null) return null;

		if (typeof val === 'string') {
			const parsed = Number(val);
			if (isNaN(parsed)) return val;
			return new Intl.NumberFormat('es-MX').format(parsed);
		}

		return new Intl.NumberFormat('es-MX').format(val);
	};

	return (
		<div className={styles.tableInfo}>
			{isLoadingTable
				? Array.from({ length: 7 }).map((_, index) => (
						<div key={index} className={styles.skeleton}></div>
				  ))
				: Object.entries(data).map(
						([key, value]) =>
							value !== null && (
								<p key={key}>
									<span>{labelMetrics[key as keyof MetricData]} </span>
									{formatNumber(value as number)}
								</p>
							)
				  )}
		</div>
	);
}
