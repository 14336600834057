import styles from './footer.module.css';

export function FooterFreeNoticeDownload() {
	return (
		<p className={styles.nota}>
			<span>Nota:</span> En caso de dudas realice la consulta correspondiente en el
			menú Centro de Atención Empresarial / Realiza y da Seguimiento a tu solicitud
		</p>
	);
}
