export const tabsEle = [
	{
		id: 1,
		title: 'Consulta de avisos',
		disabled: false,
		checked: false,
		columns: 3,
		orderMD: 0,
		orderSM: 0,
	},
	{
		id: 2,
		title: 'Avisos de retención',
		disabled: false,
		checked: false,
		columns: 3,
		orderMD: 1,
		orderSM: 2,
	},
	{
		id: 3,
		title: 'Avisos de suspensión',
		disabled: false,
		checked: false,
		columns: 3,
		orderMD: 2,
		orderSM: 3,
	},
	{
		id: 4,
		title: 'Generador de Insumos',
		disabled: false,
		checked: false,
		columns: 3,
		orderMD: 2,
		orderSM: 3,
	},
];

export const TABS = {
	NOTICE: 0,
	ACCREDITED: 1,
	SUSPENDED: 2,
	INPUT_GENERATOR: 3,
};

export const PAGES = {
	LOGIN: 2,
	FREE_NOTICE_DOWNLOAD: 1,
};
