import { createContext, useState } from 'react';
import {
	CatalogTypeServiceOption,
	ContextProps,
	ProviderProps,
	MetricData,
	IFolioAviso,
} from './free-notice-download.interface';
import { FormValues, initialValues } from '../interfaces/form.interfaces';
import { getProfile } from '../../../utils/getProfile';

export const FreeNoticeDownloadContext = createContext<ContextProps | undefined>(
	undefined
);

interface Option {
	id: string;
	name: string;
}

export const FreeNoticeDownloadProvider = ({ children }: ProviderProps) => {
	const profile = getProfile();

	const [rfc, setRfc] = useState<string | null>(null);
	const [formValues, setFormValues] = useState<FormValues>({
		...initialValues,
		IP_NRP: profile.contacto.nrp,
	});
	const [currentTab, setCurrentTab] = useState(0);
	const [page, setPage] = useState(1);
	const [metrics, setMetrics] = useState<MetricData[]>([]);
	const [dataTable, setDataTable] = useState<any[]>([]);
	const [serviceConfirmModal, setServiceConfirmModal] = useState(false);
	const [criteriaWarningModal, setCriteriaWarningModal] = useState(false);
	const [isLoading, setIsLoading] = useState(true);
	const [isLoadingTable, setIsLoadingTable] = useState(false);
	const [folioAvisoList, setFolioAvisoList] = useState<IFolioAviso[] | []>([]);
	const [disableInputs, setDisabledInput] = useState(false);
	const [catalogTypeServiceOptions, setCatalogTypeServiceOptions] = useState<
		CatalogTypeServiceOption[]
	>([
		{
			nombre: '',
			value: '',
		},
	]);

	const [selectedOptions, setSelectedOptions] = useState<Option[] | []>([]);
	const [headersMap, setHeadersMap] = useState<any[]>([]);

	return (
		<FreeNoticeDownloadContext.Provider
			value={{
				currentTab,
				setCurrentTab,
				page,
				setPage,
				catalogTypeServiceOptions,
				setCatalogTypeServiceOptions,
				metrics,
				setMetrics,
				dataTable,
				setDataTable,
				isLoading,
				setIsLoading,
				isLoadingTable,
				setIsLoadingTable,
				serviceConfirmModal,
				setServiceConfirmModal,
				criteriaWarningModal,
				setCriteriaWarningModal,
				formValues,
				setFormValues,
				rfc,
				setRfc,
				folioAvisoList,
				setFolioAvisoList,
				disableInputs,
				setDisabledInput,
				selectedOptions,
				setSelectedOptions,
				headersMap,
				setHeadersMap,
			}}
		>
			{children}
		</FreeNoticeDownloadContext.Provider>
	);
};
