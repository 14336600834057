export const URLS = {
	EFIRMA: {
		VALIDATE: process.env.REACT_APP_EFIRMA,
	},
	CREATE_ACCOUNT: {
		VALIDATION: 'crear-cuenta/validacion',
		REGISTER_EFIRMA: 'crear-cuenta/registro-con-efirma',
		REGISTER_EBA: 'crear-cuenta/registro-con-eba',
	},
};
export const URLS_ENV: { [key: string]: string } = {
	FOOTER_A: process.env.REACT_APP_URL_FOOTER_A || 'https://portalmx.infonavit.org.mx/',
	FOOTER_B: process.env.REACT_APP_URL_FOOTER_B || 'https://portalmx.infonavit.org.mx/',
	ENCUESTA: process.env.REACT_APP_URL_ENCUESTA || 'https://portalmx.infonavit.org.mx/',
	GUIA_REGISTRO:
		process.env.REACT_APP_URL_GUIA_REGISTRO || 'https://portalmx.infonavit.org.mx/',
	GUIA_CAE: process.env.REACT_APP_URL_GUIA_CAE || 'https://portalmx.infonavit.org.mx/',
	FORM_DICTA:
		process.env.REACT_APP_URL_FORM_DICTA || 'https://portalmx.infonavit.org.mx/',
	SERVICES_A:
		process.env.REACT_APP_URL_SERVICES_A || 'https://portalmx.infonavit.org.mx/',
	SERVICES_B:
		process.env.REACT_APP_URL_SERVICES_B || 'https://portalmx.infonavit.org.mx/',
	SERVICES_C:
		process.env.REACT_APP_URL_SERVICES_C || 'https://portalmx.infonavit.org.mx/',
	GUIDE_HEADER:
		process.env.REACT_APP_URL_GUIDE_HEADER || 'https://portalmx.infonavit.org.mx/',
	GUIDE_SI_A:
		process.env.REACT_APP_URL_GUIDE_SI_A || 'https://portalmx.infonavit.org.mx/',
	GUIDE_SI_B:
		process.env.REACT_APP_URL_GUIDE_SI_B || 'https://portalmx.infonavit.org.mx/',
	LOGIN_A: process.env.REACT_APP_URL_LOGIN_A || 'https://portalmx.infonavit.org.mx/',
	LOGIN_B: process.env.REACT_APP_URL_LOGIN_B || 'https://portalmx.infonavit.org.mx/',
	LEGAL_REP_GUIDE:
		process.env.REACT_APP_URL_LEGAL_REP_GUIDE || 'https://portalmx.infonavit.org.mx/',
};
