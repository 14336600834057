import { useState, useRef, useEffect, Fragment, useCallback } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Box, Stack, Checkbox, Skeleton } from '@mui/material';
import { Pagination } from './pagination.component';
import style from './styles/table-styles.component.module.css';

interface IDataTableProps {
	headers: { id: string; name: string }[];
	data: any[];
	align?: 'left' | 'right' | 'center' | 'justify' | 'inherit';
	pagination?: boolean;
	rowsPerPage?: number;
	labelStyles?: React.CSSProperties;
	messageEmpty?: string;
	disabledRows?: string[];
	onSelectedRowsChange?: (selectedRows: any[]) => void;
	selectable?: boolean;
	isLoading?: boolean;
	idKey?: string;
}

export default function DataTable({
	headers,
	data,
	rowsPerPage = 10,
	pagination = true,
	labelStyles,
	messageEmpty,
	align,
	disabledRows = [],
	onSelectedRowsChange,
	selectable = false, // Default to false
	isLoading = false, // Default to false
	idKey = 'id', // Default ID key
}: IDataTableProps) {
	const [page, setPage] = useState<number>(1);
	const [scroll, setScroll] = useState<boolean>(false);
	const [selectedRows, setSelectedRows] = useState<string[]>([]);
	const count = Math.ceil(data.length / rowsPerPage);
	const startDataPerPage = (page - 1) * rowsPerPage;
	const endDataPerPage = startDataPerPage + rowsPerPage;

	const ParNumber = (number: number) => number % 2 === 0;

	const useHorizontalScrollEvent = (callback: any) => {
		const positionRef = useRef(0);
		return (e: any) => {
			const x = e.currentTarget.scrollLeft;
			if (x !== positionRef.current) {
				positionRef.current = x;
				callback(positionRef.current);
			}
		};
	};

	const handleScroll = useHorizontalScrollEvent((position: number) => {
		setScroll(position !== 0);
	});

	useEffect(() => {
		setPage(1);
	}, [data]);

	const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
		if (event.target.checked) {
			const newSelecteds = data
				.slice(startDataPerPage, endDataPerPage)
				.filter((row) => !disabledRows.includes(row[idKey]))
				.map((row) => row[idKey]);
			setSelectedRows(newSelecteds);
		} else {
			setSelectedRows([]);
		}
	};

	const handleClick = useCallback(
		(id: string) => {
			setSelectedRows((prevSelectedRows) => {
				const selectedIndex = prevSelectedRows.indexOf(id);
				let newSelected: string[] = [];

				if (selectedIndex === -1) {
					newSelected = newSelected.concat(prevSelectedRows, id);
				} else if (selectedIndex === 0) {
					newSelected = newSelected.concat(prevSelectedRows.slice(1));
				} else if (selectedIndex === prevSelectedRows.length - 1) {
					newSelected = newSelected.concat(prevSelectedRows.slice(0, -1));
				} else if (selectedIndex > 0) {
					newSelected = newSelected.concat(
						prevSelectedRows.slice(0, selectedIndex),
						prevSelectedRows.slice(selectedIndex + 1)
					);
				}

				return newSelected;
			});
		},
		[idKey]
	);

	useEffect(() => {
		if (onSelectedRowsChange) {
			const selectedData = data.filter((row) => selectedRows.includes(row[idKey]));
			onSelectedRowsChange(selectedData);
		}
	}, [selectedRows, data, idKey, onSelectedRowsChange]);

	useEffect(() => {
		setPage(1);
		setSelectedRows([]);
	}, [data]);

	return (
		<Fragment>
			<TableContainer
				id={style.tabla}
				component={Paper}
				sx={{ ...labelStyles }}
				onScroll={handleScroll}
			>
				<Table>
					<TableHead className={style.header} style={{ textAlign: align }}>
						<TableRow>
							{selectable && (
								<th className={style.headerCheckbox}>
									<Checkbox
										indeterminate={
											selectedRows.length > 0 &&
											selectedRows.length < data.length
										}
										checked={
											data.length > 0 &&
											selectedRows.length ===
												data.length - disabledRows.length
										}
										onChange={handleSelectAllClick}
										sx={{
											color: 'white',
											'&.Mui-checked': {
												color: '#3ABE21',
											},
										}}
									/>
								</th>
							)}
							{headers.map((header, index) => (
								<th
									className={`${
										index === 0 ? style.stickyHeader : ''
									} ${style.headerTitle} ${
										index === 0 && scroll ? style.scroll : ''
									}`}
									key={header.id}
								>
									{header.name}
								</th>
							))}
						</TableRow>
					</TableHead>
					<TableBody>
						{isLoading ? (
							Array.from(new Array(rowsPerPage)).map((_, index) => (
								<TableRow key={index}>
									{selectable && (
										<td
											className={
												ParNumber(index) ? style.par : style.impar
											}
										>
											<Skeleton
												variant="rectangular"
												width={24}
												height={24}
											/>
										</td>
									)}
									{headers.map((header, index2) => (
										<td
											key={index2}
											style={{ ...labelStyles, textAlign: align }}
											className={`${
												ParNumber(index) ? style.par : style.impar
											} ${index2 === 0 ? style.sticky : ''}
											${index2 === 0 && scroll ? style.scroll : ''}`}
										>
											<Skeleton variant="text" />
										</td>
									))}
								</TableRow>
							))
						) : data.length === 0 ? (
							<TableRow>
								<td
									colSpan={headers.length + (selectable ? 1 : 0)}
									className={style.empty}
								>
									{messageEmpty ? messageEmpty : 'Sin resultados'}
								</td>
							</TableRow>
						) : (
							data
								.slice(startDataPerPage, endDataPerPage)
								.map((row, index) => (
									<TableRow key={index}>
										{selectable && (
											<td
												className={`${
													ParNumber(index)
														? style.par
														: style.impar
												}
										`}
											>
												<Checkbox
													checked={selectedRows.includes(
														row[idKey]
													)}
													onChange={() =>
														handleClick(row[idKey])
													}
													disabled={disabledRows.includes(
														row[idKey]
													)}
													sx={{
														color: '#293990',
														'&.Mui-checked': {
															color: '#3ABE21',
														},
													}}
												/>
											</td>
										)}
										{headers.map((header, index2) => (
											<td
												style={{
													...labelStyles,
													textAlign: align,
												}}
												key={header.id}
												className={`${
													ParNumber(index)
														? style.par
														: style.impar
												} ${index2 === 0 ? style.sticky : ''}
											${index2 === 0 && scroll ? style.scroll : ''}
											`}
											>
												{row[header.id]}
											</td>
										))}
									</TableRow>
								))
						)}
					</TableBody>
				</Table>
			</TableContainer>
			{pagination && count > 1 && (
				<Box display="flex" justifyContent="center" flex={1} marginTop="20px">
					<Stack spacing={2} className={style.stack}>
						<Pagination
							showFirstButton
							showLastButton
							count={count}
							onChange={(_e, page) => setPage(page)}
						/>
					</Stack>
				</Box>
			)}
		</Fragment>
	);
}
