import { useEffect } from 'react';
import { useFreeNoticeDownload } from '../context/useFreeNoticeDownloadContext';
import { useCatalogTypeService, IGetCatalogTypeService } from './useCatalogTypeNotice';
import { useGeneralMetrics } from './useGeneralMetrics';
import { useTableService } from './useTableService';
import { useLoading } from '../../../hooks/useLoading';
import { TABS } from '../constants/tabs.constants';
import { initialValues } from '../interfaces/form.interfaces';
import { useGetRFCbyNRPService } from './useGetRFCbyNRPService';
import { getProfile } from '../../../utils/getProfile';

export function useFreeNoticeDownloadTabs() {
	const {
		rfc,
		setRfc,
		currentTab,
		setCurrentTab,
		setDataTable,
		setFormValues,
		setDisabledInput,
		setMetrics,
	} = useFreeNoticeDownload();
	const { loadingFalseTwo } = useLoading();
	const { value, status, getCatalogTypeService } = useCatalogTypeService();
	const { value: valueMetrics, status: statusMetrics } = useGeneralMetrics();
	const { getTableService } = useTableService();

	const { status: statusRFC, value: valueRFC } = useGetRFCbyNRPService();

	const profile = getProfile();

	useEffect(() => {
		setDataTable([]);
		setMetrics([]);
	}, [currentTab]);

	useEffect(() => {
		if (valueRFC?.code === '0000') {
			if (valueRFC.data.rfc) {
				setRfc(valueRFC.data.rfc);
			} else {
				//mostrar mensaje de error
			}
		} else {
			//mostrar mensaje de error
		}
	}, [statusRFC]);

	useEffect(() => {
		const type: IGetCatalogTypeService = (() => {
			switch (currentTab) {
				case TABS.NOTICE:
					return { typeNotice: '01', ipNotice: '0' };
				case TABS.ACCREDITED:
					return { typeNotice: '01', ipNotice: '1' };
				case TABS.SUSPENDED:
					return { typeNotice: '10', ipNotice: '2' };
				default:
					return { typeNotice: '01', ipNotice: '0' };
			}
		})();
		if (rfc) {
			getCatalogTypeService(type);
		}
		setFormValues({ ...initialValues, IP_NRP: profile.contacto.nrp });
		setDisabledInput(false);
	}, [currentTab, rfc]);

	useEffect(() => {
		if (status === 'success') {
			if (value?.code === '0001') {
				loadingFalseTwo();
				setDataTable([]);
			}
		}
	}, [status]);

	useEffect(() => {
		if (statusMetrics === 'success') {
			if (valueMetrics?.code === '0001' || valueMetrics?.code === '0000') {
				const type = (() => {
					switch (currentTab) {
						case TABS.NOTICE:
							return { IP_CLSN_AVSO: '0', IP_ULTO_AVSO: '1' };
						case TABS.ACCREDITED:
							return { IP_CLSN_AVSO: '1', IP_ULTO_AVSO: '1' };
						case TABS.SUSPENDED:
							return { IP_CLSN_AVSO: '2', IP_ULTO_AVSO: '1' };
						default:
							return { IP_CLSN_AVSO: '0', IP_ULTO_AVSO: '1' };
					}
				})();
				getTableService(type);
			}
		}
	}, [statusMetrics]);

	return {
		currentTab,
		setCurrentTab,
	};
}
