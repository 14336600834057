import { Fragment } from 'react';
// Dependencies
import { Backdrop } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { useSelector } from 'react-redux';

//
import gifLoading from '../assets/gifts/logo-min.gif';
import { themBackdrop } from '../config/theme';
import { RootState } from '../store';
import styles from './styles/loading-styles.component.module.css';

const ModalLoading = () => {
	const { loading } = useSelector((state: RootState) => state.loading);
	return (
		<Fragment>
			{loading && (
				<ThemeProvider theme={themBackdrop}>
					<Backdrop open={loading}>
						<div className={styles.containerLoading}>
							<img
								src={gifLoading}
								alt="loading-infonavit-gift"
								className={styles.imgGift}
							/>
							<p className={styles.textLoading}>Cargando...</p>
						</div>
					</Backdrop>
				</ThemeProvider>
			)}
		</Fragment>
	);
};

export default ModalLoading;
