import useModal from '../../../hooks/useModal';
import { useLoading } from '../../../hooks/useLoading';
import { useApiRequest } from '../../../hooks/useApiRequest';
import { useEffect, useState } from 'react';
import { columnsServiceAdapters } from '../adapters/catalog-type-service.adapters';
import { CatalogTypeServiceOption } from '../context/free-notice-download.interface';

interface ILoginBalPiParams {
	IP_CLSN_AVSO: string;
	IP_TIPO_AVSO: string;
}

interface IColumns {
	IdClma: string;
	DscnClma: string;
}

export function useSupplyColumns() {
	const { openErrorModal } = useModal();
	const { loadingFalseTwo } = useLoading();
	const { execute, value, status } = useApiRequest<ILoginBalPiParams, IColumns[]>({
		path: 'servicio-gratuito/insumos-columnas',
	});

	const [columnsOptions, setColumnsOptions] = useState<CatalogTypeServiceOption[]>([]);

	useEffect(() => {
		if (status === 'success') {
			if (value?.code === '0001') {
				setColumnsOptions(columnsServiceAdapters(value.data!));
			}
		} else if (status === 'error') {
			openErrorModal(value?.message);
			loadingFalseTwo();
		}
	}, [status]);

	useEffect(() => {
		execute();
	}, []);

	return {
		value,
		status,
		columnsOptions,
	};
}
