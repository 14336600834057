import { useState, useRef, useEffect, Fragment, useCallback } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Box, Stack, Checkbox, Skeleton } from '@mui/material';
import { Pagination } from '../../../../components/pagination.component';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import style from './table.module.css';

interface IHeader {
	id: string;
	name: string;
	isEditing?: boolean;
}

interface IDataTableProps {
	headers: IHeader[];
	setHeaders: any;

	data: any[];
	align?: 'left' | 'right' | 'center' | 'justify' | 'inherit';
	pagination?: boolean;
	rowsPerPage?: number;
	labelStyles?: React.CSSProperties;
	messageEmpty?: string;
	disabledRows?: string[];
	onSelectedRowsChange?: (selectedRows: any[]) => void;
	selectable?: boolean;
	isLoading?: boolean;
	idKey?: string;
}

export default function DataTable({
	headers,
	setHeaders,
	data,
	rowsPerPage = 10,
	pagination = true,
	labelStyles,
	messageEmpty,
	align,
	disabledRows = [],
	onSelectedRowsChange,
	selectable = false,
	isLoading = false,
	idKey = 'id',
}: IDataTableProps) {
	const [page, setPage] = useState<number>(1);
	const [scroll, setScroll] = useState<boolean>(false);
	const [selectedRows, setSelectedRows] = useState<string[]>([]);

	const count = Math.ceil(data.length / rowsPerPage);
	const startDataPerPage = (page - 1) * rowsPerPage;
	const endDataPerPage = startDataPerPage + rowsPerPage;

	const ParNumber = (number: number) => number % 2 === 0;

	const positionRef = useRef(0);

	const handleScroll = (e: React.UIEvent<HTMLDivElement>) => {
		const x = e.currentTarget.scrollLeft;
		if (x !== positionRef.current) {
			positionRef.current = x;
			setScroll(x !== 0);
		}
	};

	useEffect(() => {
		setPage(1);
	}, [data]);

	const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
		if (event.target.checked) {
			const newSelecteds = data
				.slice(startDataPerPage, endDataPerPage)
				.filter((row) => !disabledRows.includes(row[idKey]))
				.map((row) => row[idKey]);
			setSelectedRows(newSelecteds);
		} else {
			setSelectedRows([]);
		}
	};

	const handleClick = useCallback(
		(id: string) => {
			setSelectedRows((prevSelectedRows) => {
				const selectedIndex = prevSelectedRows.indexOf(id);
				let newSelected: string[] = [];

				if (selectedIndex === -1) {
					newSelected = newSelected.concat(prevSelectedRows, id);
				} else if (selectedIndex === 0) {
					newSelected = newSelected.concat(prevSelectedRows.slice(1));
				} else if (selectedIndex === prevSelectedRows.length - 1) {
					newSelected = newSelected.concat(prevSelectedRows.slice(0, -1));
				} else if (selectedIndex > 0) {
					newSelected = newSelected.concat(
						prevSelectedRows.slice(0, selectedIndex),
						prevSelectedRows.slice(selectedIndex + 1)
					);
				}
				return newSelected;
			});
		},
		[idKey]
	);

	useEffect(() => {
		if (onSelectedRowsChange) {
			const selectedData = data.filter((row) => selectedRows.includes(row[idKey]));
			onSelectedRowsChange(selectedData);
		}
	}, [selectedRows, data, idKey, onSelectedRowsChange]);

	const handleHeaderDoubleClick = useCallback(
		(headerId: string) => {
			setHeaders((prev: any) =>
				prev.map((h: any) =>
					h.id === headerId
						? { ...h, isEditing: true }
						: { ...h, isEditing: false }
				)
			);
		},
		[setHeaders]
	);

	const sanitizeInput = (value: string) => {
		return value.replace(/[^a-zA-Z0-9áéíóúÁÉÍÓÚñÑ_\-\s]/g, '');
	};

	const handleHeaderNameChange = useCallback(
		(e: React.ChangeEvent<HTMLInputElement>, headerId: string) => {
			let { value } = e.target;
			value = sanitizeInput(value);

			setHeaders((prev: any) =>
				prev.map((h: any) => (h.id === headerId ? { ...h, name: value } : h))
			);
		},
		[setHeaders]
	);

	const handleHeaderBlur = useCallback(
		(headerId: string) => {
			setHeaders((prev: any) =>
				prev.map((h: any) => (h.id === headerId ? { ...h, isEditing: false } : h))
			);
		},
		[setHeaders]
	);

	function swap<T>(array: T[], i: number, j: number) {
		const newArr = [...array];
		[newArr[i], newArr[j]] = [newArr[j], newArr[i]];
		return newArr;
	}

	const handleMoveLeft = useCallback(
		(index: number) => {
			if (index === 0) return;
			setHeaders((prev: any) => swap(prev, index, index - 1));
		},
		[setHeaders]
	);

	const handleMoveRight = useCallback(
		(index: number) => {
			setHeaders((prev: any) => {
				if (index === prev.length - 1) return prev;
				return swap(prev, index, index + 1);
			});
		},
		[setHeaders]
	);

	return (
		<Fragment>
			<TableContainer
				id={style.tabla}
				component={Paper}
				sx={{ ...labelStyles }}
				onScroll={handleScroll}
			>
				<Table>
					<TableHead className={style.header} style={{ textAlign: align }}>
						<TableRow>
							{selectable && (
								<th className={style.headerCheckbox}>
									<Checkbox
										indeterminate={
											selectedRows.length > 0 &&
											selectedRows.length < data.length
										}
										checked={
											data.length > 0 &&
											selectedRows.length ===
												data.length - disabledRows.length
										}
										onChange={handleSelectAllClick}
										sx={{
											color: 'white',
											'&.Mui-checked': {
												color: '#3ABE21',
											},
										}}
									/>
								</th>
							)}
							{headers.map((header, index) => (
								<th
									className={`
                    ${index === 0 ? style.stickyHeader : ''}
                    ${style.headerTitle}
                    ${index === 0 && scroll ? style.scroll : ''}
                  `}
									key={header.id}
								>
									<div
										style={{
											display: 'flex',
											alignItems: 'center',
											justifyContent: 'center',
										}}
									>
										<ChevronLeftIcon
											onClick={() => handleMoveLeft(index)}
											style={{ marginRight: 4 }}
										/>
										{header.isEditing ? (
											<input
												type="text"
												value={header.name}
												autoFocus
												onChange={(e) =>
													handleHeaderNameChange(e, header.id)
												}
												onBlur={() => handleHeaderBlur(header.id)}
												onKeyDown={(e) => {
													if (e.key === 'Enter') {
														handleHeaderBlur(header.id);
													}
												}}
												style={{ marginRight: 4 }}
											/>
										) : (
											<span
												onDoubleClick={() =>
													handleHeaderDoubleClick(header.id)
												}
												style={{ marginRight: 4 }}
											>
												{header.name}
											</span>
										)}

										<ChevronRightIcon
											onClick={() => handleMoveRight(index)}
										/>
									</div>
								</th>
							))}
						</TableRow>
					</TableHead>

					<TableBody>
						{isLoading ? (
							Array.from(new Array(rowsPerPage)).map((_, index) => (
								<TableRow key={index}>
									{selectable && (
										<td
											className={
												ParNumber(index) ? style.par : style.impar
											}
										>
											<Skeleton
												variant="rectangular"
												width={24}
												height={24}
											/>
										</td>
									)}
									{headers.map((header, index2) => (
										<td
											key={index2}
											style={{ ...labelStyles, textAlign: align }}
											className={`
                        ${ParNumber(index) ? style.par : style.impar}
                        ${index2 === 0 ? style.sticky : ''}
                        ${index2 === 0 && scroll ? style.scroll : ''}
                      `}
										>
											<Skeleton variant="text" />
										</td>
									))}
								</TableRow>
							))
						) : data.length === 0 ? (
							<TableRow>
								<td
									colSpan={headers.length + (selectable ? 1 : 0)}
									className={style.empty}
								>
									{messageEmpty ? messageEmpty : 'Sin resultados'}
								</td>
							</TableRow>
						) : (
							data
								.slice(startDataPerPage, endDataPerPage)
								.map((row, index) => (
									<TableRow key={index}>
										{selectable && (
											<td
												className={
													ParNumber(index)
														? style.par
														: style.impar
												}
											>
												<Checkbox
													checked={selectedRows.includes(
														row[idKey]
													)}
													onChange={() =>
														handleClick(row[idKey])
													}
													disabled={disabledRows.includes(
														row[idKey]
													)}
													sx={{
														color: '#293990',
														'&.Mui-checked': {
															color: '#3ABE21',
														},
													}}
												/>
											</td>
										)}
										{headers.map((header, index2) => (
											<td
												style={{
													...labelStyles,
													textAlign: align,
												}}
												key={header.id}
												className={`
                          ${ParNumber(index) ? style.par : style.impar}
                          ${index2 === 0 ? style.sticky : ''}
                          ${index2 === 0 && scroll ? style.scroll : ''}
                        `}
											>
												{row[header.id]}
											</td>
										))}
									</TableRow>
								))
						)}
					</TableBody>
				</Table>
			</TableContainer>

			{pagination && count > 1 && (
				<Box display="flex" justifyContent="center" flex={1} marginTop="20px">
					<Stack spacing={2} className={style.stack}>
						<Pagination
							showFirstButton
							showLastButton
							count={count}
							onChange={(_e, page) => setPage(page)}
						/>
					</Stack>
				</Box>
			)}
		</Fragment>
	);
}
