import CustomModal from '../../../../components/modal.component';
import CustomButton from '../../../../components/button.component';
import style from './criteria-warning-modal.module.css';
import { useFreeNoticeDownload } from '../../context/useFreeNoticeDownloadContext';

interface CriteriaWarningModalProps {
	onContinue: () => void;
}

export function CriteriaWarningModal({ onContinue }: CriteriaWarningModalProps) {
	const { criteriaWarningModal, setCriteriaWarningModal } = useFreeNoticeDownload();

	return (
		<CustomModal openModal={criteriaWarningModal} title="Advertencia" type="normal">
			<div className={style.content}>
				<p>
					No definió ningún criterio de selección ¿esto es correcto?
					Advertencia: Si hace clic en “Sí” el sistema intentará recuperar todos
					los avisos asociados a su RFC
				</p>
				<div className={style.buttons}>
					<CustomButton
						variant="outlined"
						onClick={() => setCriteriaWarningModal(false)}
					>
						No
					</CustomButton>
					<CustomButton
						onClick={() => {
							setCriteriaWarningModal(false);
							onContinue();
						}}
					>
						Sí
					</CustomButton>
				</div>
			</div>
		</CustomModal>
	);
}
