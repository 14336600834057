import { useDownloadIndividualPDF } from '../hooks/useDownloadIndividualPDF';
import downloadSvg from '../../../assets/svg/download.svg';

export const useTableAdapter = () => {
	const { downloadIndividualPdf } = useDownloadIndividualPDF();

	const responseTableAdapter = (data: any) => {
		return data.map((item: any) => {
			return {
				...item,
				actions: (
					<img
						style={{ cursor: 'pointer' }}
						src={downloadSvg}
						alt="downloadPDF"
						onClick={() => downloadIndividualPdf(item)}
					/>
				),
			};
		});
	};

	return {
		responseTableAdapter,
	};
};
