import { useApiRequest } from '../../../hooks/useApiRequest';

export interface ILogService {
	type: string;
	request: string;
	response: string;
}

export function useLogService() {
	const { execute, value, status } = useApiRequest<ILogService, unknown>({
		path: 'servicio-gratuito/descargar-aviso-masiva',
	});

	const saveLog = (data: ILogService) => {
		execute(data);
	};

	return {
		value,
		status,
		saveLog,
	};
}
