import useModal from '../../../hooks/useModal';
import { useLoading } from '../../../hooks/useLoading';
import { useApiRequest } from '../../../hooks/useApiRequest';
import { useFreeNoticeDownload } from '../context/useFreeNoticeDownloadContext';
import { useEffect } from 'react';
import { MetricData } from '../context/free-notice-download.interface';

import { IGetTableParams } from './useTableService';

interface ILoginBalPiParams {
	IP_RFC: string;
	IP_ULTO_AVSO?: string | null;
	IP_CLSN_AVSO: string;
}

export function useGeneralMetrics() {
	const { openErrorModal } = useModal();
	const { loadingFalseTwo, loadingTrueTwo } = useLoading();
	const { setMetrics, setIsLoadingTable, rfc } = useFreeNoticeDownload();
	const { execute, value, status } = useApiRequest<ILoginBalPiParams, MetricData[]>({
		path: 'servicio-gratuito/metricas-generales',
	});

	useEffect(() => {
		if (status === 'success') {
			if (value?.code === '0000') {
				setMetrics([]);
			}
			if (value?.code === '0001') {
				setMetrics(value.data!);
			}
		} else if (status === 'error') {
			openErrorModal(value?.message);
			loadingFalseTwo();
		}
	}, [status]);

	const getGeneralMetrics = (data: IGetTableParams) => {
		setIsLoadingTable(true);
		loadingTrueTwo();
		execute({
			...data,
			IP_ULTO_AVSO: data.IP_ULTO_AVSO || null,
			IP_RFC: rfc || '',
		});
	};

	return {
		value,
		status,
		getGeneralMetrics,
	};
}
