import { Fragment } from 'react';
import usePagination from '@mui/material/usePagination';
import styles from './styles/pagination-styles.component.module.css';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import LastPageIcon from '@mui/icons-material/LastPage';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';

interface IDataTableProps {
	count: number;
	onChange: (e: React.ChangeEvent<unknown>, page: number) => void;
	showFirstButton?: boolean;
	showLastButton?: boolean;
}

export function Pagination({
	count,
	onChange,
	showFirstButton,
	showLastButton,
}: IDataTableProps) {
	const handleChange = (event: React.ChangeEvent<unknown>, page: number) => {
		onChange(event, page);
	};

	const { items } = usePagination({
		count: count,
		onChange: handleChange,
		showFirstButton,
		showLastButton,
	});
	return (
		<div className={styles.pagination}>
			<div className={styles.previous}>
				<Fragment>
					{items.map(({ page, type, selected, ...item }) => {
						if (type === 'first') {
							return (
								<button
									key={type}
									type="button"
									className={styles.previousItems}
									{...item}
								>
									<FirstPageIcon />
								</button>
							);
						}
						return null;
					})}
					{items.map(({ page, type, selected, ...item }) => {
						if (type === 'previous') {
							return (
								<button
									key={type}
									type="button"
									className={styles.previousItems}
									{...item}
								>
									<NavigateBeforeIcon />
								</button>
							);
						}
						return null;
					})}
				</Fragment>
			</div>
			<div className={styles.pages}>
				{items.map(({ page, type, selected, ...item }) => {
					if (type === 'page') {
						return (
							<button
								key={page}
								type="button"
								className={`${styles.paginationItem} ${
									selected ? styles.selected : ''
								}`}
								{...item}
							>
								{page}
							</button>
						);
					}
					return null;
				})}
			</div>
			<div className={styles.previous}>
				{items.map(({ page, type, selected, ...item }) => {
					if (type === 'next') {
						return (
							<button
								key={type}
								type="button"
								className={styles.previousItems}
								{...item}
							>
								<NavigateNextIcon />
							</button>
						);
					}
					return null;
				})}
				{items.map(({ page, type, selected, ...item }) => {
					if (type === 'last') {
						return (
							<button
								key={type}
								type="button"
								className={styles.previousItems}
								{...item}
							>
								<LastPageIcon />
							</button>
						);
					}
					return null;
				})}
			</div>
		</div>
	);
}
