import CustomModal from '../../../../components/modal.component';
import CustomButton from '../../../../components/button.component';
import style from './service-confirm-moda.module.css';
import { useFreeNoticeDownload } from '../../context/useFreeNoticeDownloadContext';

interface ServiceConfirmModalProps {
	onContinue: () => void;
}

export function ServiceConfirmModal({ onContinue }: ServiceConfirmModalProps) {
	const { serviceConfirmModal, setServiceConfirmModal } = useFreeNoticeDownload();

	return (
		<CustomModal openModal={serviceConfirmModal} title="Advertencia" type="normal">
			<div className={style.content}>
				<p>
					Está a punto de ejecutar una consulta al Servicio de Intercomunicación
					de Avisos, la información que recupere se le agregará la fecha y la
					hora de su consulta y se entenderá que el aportante del RFC
					autenticado en el presente sistema ya tiene conocimiento de los avisos
					descargados ¿Desea continuar?
				</p>
				<div className={style.buttons}>
					<CustomButton
						variant="outlined"
						onClick={() => setServiceConfirmModal(false)}
					>
						No
					</CustomButton>
					<CustomButton onClick={onContinue}>Sí</CustomButton>
				</div>
			</div>
		</CustomModal>
	);
}
