/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useState } from 'react';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { setLoading } from '../store/modules/loading';
import { INTERNAL_MESSAGES } from '../config/response-codes';
import { encodeAllJSONData } from '../utils/encrypt';
import { HTTP_METHODS } from '../constants/http-methods.constants';

export interface IMainResponse<I> {
	code: string;
	internalCode: string | null;
	message: string;
	data: I | null;
}

interface IRequestPayload {
	path: string;
	headers?: {
		[key: string]: string;
	};
	baseURL?: string;
	port?: string;
	method?: 'GET' | 'POST' | 'PUT' | 'DELETE' | 'OPTIONS';
	notEncrypted?: boolean;
	nec?: boolean;
}

export const useApiRequest = <ParamsType, ResponseType>(
	{
		port,
		path,
		headers,
		baseURL = process.env.REACT_APP_API_URL,
		method,
		notEncrypted = false,
		nec = false,
	}: IRequestPayload,
	immediate = false
) => {
	const [status, setStatus] = useState<'pending' | 'success' | 'error'>('pending');
	const [value, setValue] = useState<IMainResponse<ResponseType> | null>(null);
	const dispatch = useDispatch();
	let headersBuild = {};

	const getTokenAuth = (): string => {
		const data = JSON.parse(sessionStorage.getItem('userData')!);
		const token = data?.token || '';

		if (token) {
			return `Bearer ${token}`;
		}
		return '';
	};

	const getKey = async (): Promise<string> => {
		const kValue = sessionStorage.getItem('key') || '';
		if (kValue) {
			return kValue;
		}
		const response = await axios({
			url: `${process.env.REACT_APP_API_URL}/autentica/key`,
			method: HTTP_METHODS.POST,
			headers: {
				'Content-Type': 'application/json',
			},
		});
		sessionStorage.setItem('key', response.data.k);
		return response.data.k || '';
	};

	const execute = useCallback(async (params?: ParamsType) => {
		setStatus('pending');
		setValue(null);
		const token = getTokenAuth();
		if (token) {
			headers = {
				...headers,
				Authorization: token,
			};
		}

		if (process.env.REACT_APP_ENV === 'qa') {
			if (nec === false) {
				headersBuild = {
					'X-Build-Frontend-Branch': process.env.REACT_APP_BRANCH,
					'X-Build-Frontend-BuildDate': process.env.REACT_APP_DATE_BUILD,
					'X-Build-Frontend-VersionTag': process.env.REACT_APP_TAG,
					'X-Build-Frontend-CommitHashShort':
						process.env.REACT_APP_COMMIT_HASH_SHORT,
					'X-Build-Frontend-CommitMessage':
						process.env.REACT_APP_COMMIT_MESSAGE,
					'X-Build-Frontend-CommitHashLong':
						process.env.REACT_APP_COMMIT_HASH_LONG,
					'X-Build-Frontend-CommitDate': process.env.REACT_APP_COMMIT_DATE,
				};
			}
		}

		try {
			const key = await getKey();
			//console.log(params);
			const paramsString = { data: JSON.stringify(params) };
			const dataEnc = await encodeAllJSONData(paramsString, key);

			const url =
				process.env.NODE_ENV === 'development'
					? port
						? `http://localhost:${port}/api/v1/${path}`
						: `${baseURL}/${path}`
					: `${baseURL}/${path}`;

			const response = await axios({
				url: url,
				method: method ? method : HTTP_METHODS.POST,
				headers: {
					'Content-Type': 'application/json',
					...headers,
					...headersBuild,
				},
				data: notEncrypted ? params : { ...dataEnc },
			});
			setValue(response.data);
			setStatus('success');
		} catch (error: any) {
			if (401 === error.response.status) {
				//temporal hasta que haya protección de rutas y revisión de la caducidad del token
				sessionStorage.removeItem('userData');
				sessionStorage.removeItem('key');
				sessionStorage.removeItem('servicios');
				setValue(error?.response?.data);
				return;
			} else if (error.response.status !== 200 || error.response.status !== 204) {
				setValue({
					message: INTERNAL_MESSAGES.ERROR_MESSAGE,
					data: null,
					code: '',
					internalCode: '',
				});
			} else if (error?.response?.data) {
				setValue(error?.response?.data);
			}
			setStatus('error');
			dispatch(setLoading({ loading: false }));
		}
	}, []);

	useEffect(() => {
		if (immediate) {
			execute();
		}
	}, [execute, immediate]);

	return { execute, status, value, getKey };
};
